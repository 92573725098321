import React, { useState } from "react";
const TerminosCondiciones = () => {
  const [checkbox, guardarCheckbox] = useState({
    terminos: false,
    contacto: false,
  });

  const { terminos, contacto } = checkbox;

  const obtenerInformacion = (e) => {
    guardarCheckbox({
      ...checkbox,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <div>
        <input
          type="checkbox"
          id="terminos"
          name="terminos"
          value={terminos}
          onChange={obtenerInformacion}
        />{" "}
        Acepto Términos y Condiciones
      </div>
      <div>
        <input
          type="checkbox"
          id="contacto"
          name="contacto"
          value={contacto}
          onChange={obtenerInformacion}
        />{" "}
        Acepto que CAFFENIO me contacte con fines publicitarios
      </div>
    </>
  );
};

export default TerminosCondiciones;
