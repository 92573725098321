import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Button } from "@material-ui/core";
import { Encrypt, Decrypt } from "../../Utils/EncryptDecrypt";
import clienteAxios from "../../config/axios";
import "./MembresiaDireccion.css";
import Error from "../../Error";
const AgregarDireccion = ({
  guardarMostrar,
  guardarActualizar,
  guardarMostrarmodal,
}) => {
  const [error, guardarError] = useState("");
  const [estados, guardarEstados] = useState([]);
  const [municipios, guardarMunicipios] = useState([]);
  const [colonias, guardarColonias] = useState([]);
  const [estado, guardarEstado] = useState(null);
  const [municipio, guardarMunicipio] = useState(null);
  const [colonia, guardarColonia] = useState(null);
  const [direccion, guardarDireccion] = useState([]);

  useEffect(() => {
    if (Decrypt("token") == null) {
      window.location.href = "inicio/";
    }
    const obtenerEstados = async () => {
      await clienteAxios("/api/direccion/get_estados/", {
        headers: {
          Authorization: "Bearer " + Decrypt("token"),
        },
      })
        .then((respuesta) => {
          guardarEstados(respuesta.data);
        })
        .catch((error) => {
          console.log(
            "No puede iniciar sesión con las credenciales proporcionadas."
          );
          guardarError(error);
        });
    };
    obtenerEstados();
  }, []);

  const onChangeEstado = async (estadoSeleccionado) => {
    guardarEstado(estadoSeleccionado);
    const url =
      "/api/direccion/get_municipios/?estado=" + estadoSeleccionado.nombre;
    await clienteAxios(url, {
      headers: {
        Authorization: "Bearer " + Decrypt("token"),
      },
    })
      .then((respuesta) => {
        guardarMunicipios(respuesta.data);
      })
      .catch((error) => {
        console.log(
          "No puede iniciar sesión con las credenciales proporcionadas."
        );
        guardarError(error);
      });
  };

  const onChangeMunicipio = async (municipioSeleccionado) => {
    guardarMunicipio(municipioSeleccionado);
    const url =
      "/api/direccion/get_colonias/?municipio=" + municipioSeleccionado.nombre;
    await clienteAxios(url, {
      headers: {
        Authorization: "Bearer " + Decrypt("token"),
      },
    })
      .then((respuesta) => {
        guardarColonias(respuesta.data);
      })
      .catch((error) => {
        console.log(
          "No puede iniciar sesión con las credenciales proporcionadas."
        );
        guardarError(error);
      });
  };

  const onChangeColonia = (coloniaSeleccionado) => {
    guardarColonia(coloniaSeleccionado);
  };

  const onBlurDireccion = (e) => {
    guardarDireccion({ ...direccion, [e.target.name]: e.target.value });
  };

  const {
    nombrePerfil,
    principal,
    activo,
    codigoPostal,
    numero,
    calle,
    calleOpcional,
  } = direccion;

  console.log("DIRS", direccion);

  const volver = () => {
    window.location.href = "confirmar-direccion/";
  };
  const Agregar = async (e) => {
    e.preventDefault();
    console.log("DIR", direccion);

    if (calle !== "" || calle != null) {
      let cp = false;
      let nombre_perfil = "Secundario";
      if (principal === "on") {
        cp = true;
        nombre_perfil = "Principal";
      }
      let data = {
        nombre_perfil: nombre_perfil,
        principal: cp,
        activo: true,
        estado: estado.nombre,
        municipio: municipio.nombre,
        colonia: colonia.nombre,
        codigo_postal: codigoPostal,
        numero: numero,
        calle: calle,
        calle_opcional: calleOpcional,
      };
      let headers = {
        headers: {
          Authorization: "Bearer " + Decrypt("token"),
        },
      };
      await clienteAxios
        .post("/api/perfil_direccion/", data, headers)
        .then((respuesta) => {
          guardarActualizar(true);
          guardarMostrar(false);
          guardarMostrarmodal(false);
        })
        .catch((error) => {
          console.log(
            "No puede iniciar sesión con las credenciales proporcionadas."
          );
          console.log(error);
          guardarError(error);
        });
    } else {
      guardarError("Falta capturar información");
    }
  };

  return (
    <>
      {error ? <Error mensaje={error} /> : null}
      <div className="agregar-direccion-contenedor">
        <form action="">
          <div className="row modal-ptb-4vh">
            <div className="col-md-4">
              <label className="modal-titulo" htmlFor="">
                Estado
              </label>
              <Select
                placeholder="Seleccionar estado"
                value={estado}
                options={estados}
                onChange={onChangeEstado}
                getOptionLabel={(x) => x.nombre}
                getOptionValue={(x) => x.nombre}
              />
            </div>
            <div className="col-md-4">
              <label className="modal-titulo" htmlFor="">
                Municipio
              </label>
              <Select
                placeholder="Seleccionar municipio"
                value={municipio}
                options={municipios}
                onChange={onChangeMunicipio}
                getOptionLabel={(x) => x.nombre}
                getOptionValue={(x) => x.nombre}
              />
            </div>
            <div className="col-md-4">
              <label className="modal-titulo" htmlFor="">
                Colonia
              </label>
              <Select
                placeholder="Seleccionar colonia"
                value={colonia}
                options={colonias}
                onChange={onChangeColonia}
                getOptionLabel={(x) => x.nombre}
                getOptionValue={(x) => x.nombre}
              />
            </div>
          </div>
          <div className="row modal-ptb-4vh">
            <div className="col-md-6">
              <label className="modal-titulo" htmlFor="">
                Calle
              </label>
              <input
                type="text"
                name="calle"
                id="calle"
                value={calle}
                onBlur={onBlurDireccion}
              />
            </div>
            <div className="col-md-2">
              <label className="modal-titulo" htmlFor="">
                Número
              </label>
              <input
                type="text"
                name="numero"
                id="numero"
                value={numero}
                onBlur={onBlurDireccion}
              />
            </div>
            <div className="col-md-4">
              <label className="modal-titulo" htmlFor="">
                Código postal
              </label>
              <input
                type="text"
                name="codigoPostal"
                id="codigoPostal"
                value={codigoPostal}
                onBlur={onBlurDireccion}
              />
            </div>
          </div>
          <div className="row modal-ptb-4vh">
            <div className="col-md-6">
              <label className="modal-titulo" htmlFor="">
                Calle (opcional)
              </label>
              <input
                type="text"
                name="calleOpcional"
                id="calleOpcional"
                value={calleOpcional}
                onBlur={onBlurDireccion}
              />
            </div>
            <div className="col-md-2 modal-padre-centrar-vertical">
              <input
                type="checkbox"
                name="principal"
                id="principal"
                className="modal-checkbox"
                checked={principal}
                onBlur={onBlurDireccion}
              />
              <label className="modal-titulo" htmlFor="">
                Principal
              </label>
            </div>
            {/* <div className="col-md-2 modal-padre-centrar-vertical">
              <input
                type="checkbox"
                name="activo"
                id="activo"
                className="modal-checkbox"
                checked={activo}
                onChange={onChangeDireccion}
              />
              <label className="modal-titulo" htmlFor="">
                Activo
              </label>
            </div> */}
          </div>
        </form>
        <div className="fan-alineacion-derecha agregar-direccion-mtop10">
          <Button
            variant="outlined"
            onClick={volver}
            className="fan-btn-noreactivar"
          >
            Volver sin guardar
          </Button>
          <Button
            variant="outlined"
            onClick={Agregar}
            className="fan-btn-reactivar"
          >
            Guardar dirección
          </Button>
        </div>
        {/* <div className="row">
          <div className="col-md-6">
            <div className="modal-contenedor-boton modal-derecha">
              <a
                href="#"
                className="modal-boton modal-boton-rojo"
                onClick={volver}
              >
                Volver sin guardar
              </a>
            </div>
          </div>
          <div className="col-md-6">
            <div className="modal-contenedor-boton modal-izquierda">
              <a
                href="#"
                className="modal-boton modal-boton-verde"
                onClick={Agregar}
              >
                Agregar
              </a>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default AgregarDireccion;
