import React from "react";
const Spinner = () => {
  return (
    <>
      <div className="spinner">
        <img
          src="/wp-content/uploads/sites/2/2021/09/isotipo-beat.gif"
          alt=""
        />
        <div>Procesando ...</div>
      </div>
    </>
  );
};

export default Spinner;
