import React from "react";
import { Button } from "@material-ui/core";
const NoReanudarMembresia = () => {
  const iraperfil = () => {
    window.location.href = "mi-perfil/";
  };
  const iraplan = () => {
    window.location.href = "plan/";
  };
  return (
    <>
      <div className="reanudar-membresia-contenedor">
        <div className="pb5 pd5">
          {/* <div className="modalnr-alineacion-centro">
            <img
              src="http://34.66.128.168/micaffenio/wp-content/uploads/sites/2/2021/08/grupo-878.png"
              alt=""
              className="img-25"
            />
          </div> */}
          {/* <label className="fan-etiqueta-titulo modalnr-alineacion-centro">
            Te extrañaremos, CAFFENIO Lover
          </label> */}
          {/* {membresiavigente.map((membresia) => ( */}
          <div className="row modal-ptb-4vh">
            {/* <label className="fan-etiqueta-descripcion">
              Lamentamos que no renueves tu membresía. Si cambias de opinión,
              puedes seleccionar un nuevo plan.
            </label> */}
            <div className="fan-alineacion-derecha">
              <Button
                variant="outlined"
                onClick={iraperfil}
                className="fan-btn-noreactivar"
              >
                Ir a mi perfil
              </Button>
              <div className="btn-editar modalnr-btn-editar">
                O{" "}
                <a href="#!" onClick={iraplan}>
                  seleccionar un nuevo plan
                </a>
              </div>
              {/* <Button
                variant="outlined"
                onClick={iraplan}
                className="fan-btn-reactivar"
              >
                Reactivar
              </Button> */}
            </div>
          </div>
          {/* ))} */}
        </div>
      </div>
    </>
  );
};

export default NoReanudarMembresia;
