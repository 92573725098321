import React from "react";
const VerAgregarDireccion = ({ Direcciones, Ver }) => {
  return (
    <>
      {!Ver ? (
        <>
          <div className="miperfil-columnas pb5 pd5">
            <label className="miperfil-etiqueta-titulo">Dirección</label>
            {Direcciones.filter(
              (direccion) =>
                direccion.principal === true && direccion.activo === true
            ).map((direccionPrincipal) => (
              <div className="miperfil-etiqueta-descripcion">
                {direccionPrincipal.calle} #{direccionPrincipal.numero} entre{" "}
                {direccionPrincipal.calle_opcional} <br />
                Colonia {direccionPrincipal.colonia}{" "}
                {direccionPrincipal.municipio}, {direccionPrincipal.estado}
                <br />
                {" CP. "} {direccionPrincipal.codigo_postal}
              </div>
            ))}
          </div>
          <div className="miperfil-columnas pb5 pd5">
            <label className="miperfil-etiqueta-titulo">
              Otras direcciones
            </label>
            {Direcciones.filter(
              (otradireccion) =>
                otradireccion.principal === false &&
                otradireccion.activo === true
            )
              .slice(0, 1)
              .map((direccionSecundaria) => (
                <div className="miperfil-etiqueta-descripcion">
                  {direccionSecundaria.calle} #{direccionSecundaria.numero}{" "}
                  entre {direccionSecundaria.calle_opcional} <br />
                  Colonia {direccionSecundaria.colonia}{" "}
                  {direccionSecundaria.municipio}, {direccionSecundaria.estado}
                  <br />
                  {" CP. "} {direccionSecundaria.codigo_postal}
                </div>
              ))}
          </div>{" "}
        </>
      ) : (
        <>
          <div className="miperfil-columnas pb5 pd5">
            <label className="miperfil-etiqueta-titulo">Dirección</label>
            {Direcciones.filter(
              (direccion) =>
                direccion.principal === true && direccion.activo === true
            ).map((direccionPrincipal) => (
              <div className="miperfil-etiqueta-descripcion">
                {direccionPrincipal.calle} #{direccionPrincipal.numero} entre{" "}
                {direccionPrincipal.calle_opcional} <br />
                Colonia {direccionPrincipal.colonia}{" "}
                {direccionPrincipal.municipio}, {direccionPrincipal.estado}
                <br />
                {" CP. "} {direccionPrincipal.codigo_postal}
              </div>
            ))}
          </div>
          <div className="miperfil-columnas pb5 pd5">
            <label className="miperfil-etiqueta-titulo">
              Otras direcciones
            </label>
            {Direcciones.filter(
              (otradireccion) =>
                otradireccion.principal === false &&
                (otradireccion.activo === true ||
                  otradireccion.activo === false)
            ).map((direccionSecundaria) => (
              <div className="miperfil-etiqueta-descripcion">
                {direccionSecundaria.calle} #{direccionSecundaria.numero} entre{" "}
                {direccionSecundaria.calle_opcional} <br />
                Colonia {direccionSecundaria.colonia}{" "}
                {direccionSecundaria.municipio}, {direccionSecundaria.estado}
                <br />
                {" CP. "} {direccionSecundaria.codigo_postal}
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default VerAgregarDireccion;
