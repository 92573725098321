import React from "react";
import "./Error.css";

const Error = ({
  mensaje,
  guardarMostrarError,
  tipoMensaje,
  tipoClose,
  img,
}) => {
  const cerrar = () => {
    guardarMostrarError(false);
  };

  return (
    <>
      <div className={`alert ${tipoMensaje} fadein alert-dismissible`}>
        <a
          href="#!"
          className={`close ${tipoClose}`}
          data-dismiss="alert"
          aria-label="close"
          title="close"
          onClick={cerrar}
        >
          ×
        </a>
        <img
          src={`http://35.239.108.106/wp-content/uploads/2021/12/${img}.png`}
          alt=""
        />{" "}
        <span className="txtMensaje">{mensaje}</span>
      </div>
    </>
  );
};

export default Error;
