import React, { useState } from "react";
import { Encrypt } from "../Utils/EncryptDecrypt";
import axios from "axios";
import clienteAxios from "../config/axios";
import Error from "../Error";
import "./login.css";

function Login() {
  const [correo, guardarCorreo] = useState(null);
  const [contrasena, guardarContrasena] = useState(null);
  const [usuarioslogin, guardarUsuarioslogin] = useState([]);
  const [token, guardarToken] = useState(null);
  const [error, guardarError] = useState("");
  const [mostrarError, guardarMostrarError] = useState(false);

  const definirCorreo = (e) => {
    guardarCorreo(e.target.value);
  };

  const definirContrasena = (e) => {
    guardarContrasena(e.target.value);
  };

  const ingresar = async (e) => {
    e.preventDefault();

    if (
      correo != "" &&
      correo != null &&
      contrasena != "" &&
      contrasena != null
    ) {
      let data = {
        user: correo,
        password: contrasena,
      };
      await clienteAxios
        .post("/api/auth/get_token/", data)
        .then((respuesta) => {
          console.log("respuesta",respuesta.data);
          guardarUsuarioslogin(respuesta.data);
          guardarToken(respuesta.data.token);
          Encrypt("token", respuesta.data.token);
          Encrypt("usuario", respuesta.data.user);

          // sessionStorage.setItem(
          //   "usuario",
          //   JSON.stringify(respuesta.data.user)
          // );

          window.location.href = "./micaffenioinicioconcuenta/";
          // window.location.href = "bienvenido/";
        })
        .catch((err) => {
          console.log(err.message);
          guardarError(
            "No puede iniciar sesión con las credenciales proporcionadas."
          );
          guardarMostrarError(true);
        });
    } else {
      guardarError("Falta capturar información");
      guardarMostrarError(true);
    }
  };

  return (
    <div>
      <form className="form-login" onSubmit={ingresar}>
        {mostrarError && (
          <>
            <Error
              mensaje={"Danger"}
              guardarMostrarError={guardarMostrarError}
              tipoMensaje={"alert-danger"}
              tipoClose={"close-danger"}
              img={"icon-material-error"}
            />
            <Error
              mensaje={"Warning"}
              guardarMostrarError={guardarMostrarError}
              tipoMensaje={"alert-warning"}
              tipoClose={"close-warning"}
              img={"icon-awesome-check-triangle"}
            />
            <Error
              mensaje={"Success"}
              guardarMostrarError={guardarMostrarError}
              tipoMensaje={"alert-success"}
              tipoClose={"close-success"}
              img={"icon-awesome-check-square"}
            />
            <Error
              mensaje={"Info"}
              guardarMostrarError={guardarMostrarError}
              tipoMensaje={"alert-info"}
              tipoClose={"close-info"}
              img={"icon-material-info"}
            />
          </>
        )}
        <div className="form-group">
          <label htmlFor="" className="label-title">
            Correo
          </label>
          <input
            type="text"
            className="form-control textInputLogin"
            placeholder="Correo"
            name="correo"
            onChange={definirCorreo}
          />
          <label htmlFor="" className="label-title">
            Contraseña
          </label>
          <input
            type="password"
            className="form-control textInputLogin"
            placeholder="Contraseña"
            name="password"
            onChange={definirContrasena}
          />
          <div className="div-link">
            <a className="link-recuperar" href="#!">
              ¿Olvidaste tu contraseña?
            </a>
            <br />
            <a className="link-recuperar" href="http://35.239.108.106/micaffenio/terminos-y-condiciones/">
              Términos y
              condiciones,
            </a>
            <a className="link-recuperar" href="http://35.239.108.106/privacy-policy/">
              Politíca de servicio y Privacidad.
            </a>
          </div>
          <input
            type="submit"
            className="btn-iniciasesion"
            value="Inicia sesión"
          />
        </div>
      </form>
    </div>
  );
}

export default Login;
