import React, { useState } from "react";
// import clienteAxios from "../config/axios";
// import { Encrypt, Decrypt } from "../Utils/EncryptDecrypt";
import { Button } from "@material-ui/core";
// import Error from "../Error";
const Beneficios = ({
  beneficios,
  membresiaMensual,
  membresiaSemestral,
  membresiaAnual,
  guardarVerMasBeneficios,
}) => {
  const cerrarModal = () => {
    guardarVerMasBeneficios(false);
  };

  return (
    <>
      <div className="inicio-contenedor">
        <div className="inicio-contenedor-beneficios">
          <div className="pb5">
            <div className="derecha">
              <a href="#!" className="beneficios-botonX" onClick={cerrarModal}>
                ×
              </a>
            </div>
            <label className="fan-etiqueta-titulo">
              Beneficios de tu nivel
            </label>
            <div className="fan-contenedor-beneficios">
              {beneficios.Membresia &&
                beneficios.Membresia == 1 &&
                membresiaAnual.promociones.map((membresia) => (
                  <div className="row inicio-ptb-05">
                    <li className="fan-beneficios-descripcion">
                      {membresia.beneficio}
                    </li>
                  </div>
                ))}
              {beneficios.Membresia &&
                beneficios.Membresia == 2 &&
                membresiaSemestral.promociones.map((membresia) => (
                  <div className="row inicio-ptb-05">
                    <li className="fan-beneficios-descripcion">
                      {membresia.beneficio}
                    </li>
                  </div>
                ))}
              {beneficios.Membresia &&
                beneficios.Membresia == 3 &&
                membresiaMensual.promociones.map((membresia) => (
                  <div className="row inicio-ptb-05">
                    <li className="fan-beneficios-descripcion">
                      {membresia.beneficio}
                    </li>
                  </div>
                ))}
              {!beneficios.Membresia && beneficios.Nivel == "Monedero HOLA" && (
                <li className="fan-beneficios-descripcion">
                  "2% de Reembolso acumulable y canjeable"
                </li>
              )}
              {!beneficios.Membresia &&
                beneficios.Nivel == "Monedero AMIGO" && (
                  <li className="fan-beneficios-descripcion">
                    "4% Reembolso acumulable y canjeable"
                  </li>
                )}
              {!beneficios.Membresia && beneficios.Nivel == "Monedero FAN" && (
                <li className="fan-beneficios-descripcion">
                  "8% Reembolso acumulable y canjeable"
                </li>
              )}
            </div>
          </div>
          <div className="fan-alineacion-sb">
            {beneficios.Membresia && (
              <a href="#!" className="beneficios-btnOpcionMembresia">
                Quiero subir de nivel…
              </a>
            )}
            {!beneficios.Membresia && (
              <a href="#!" className="beneficios-btnOpcionMembresia">
                Quiero ser miembro
              </a>
            )}

            <Button
              variant="outlined"
              onClick={cerrarModal}
              className="fan-btn-noreactivar"
            >
              Entendido
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Beneficios;
