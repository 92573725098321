import React, { useState, useEffect } from "react";
import { Encrypt, Decrypt } from "../Utils/EncryptDecrypt";
import ModalCancelarMembresia from "../Modal/ModalCancelarmembresia";
import ModalReanudarMembresia from "../Modal/ModalReanudarmembresia";
import ModalModificarMembresia from "../Modal/ModalModificarMembresia";
import Spinner from "../components/Spinner";
const MiPerfilFan = ({ MembresiaVigente }) => {
  const { Nivel } = Decrypt("usuario");
  const [renovar, guardarRenovar] = useState(false);
  const [cancelar, guardarCancelar] = useState(false);
  const [modificar, guardarModificar] = useState(false);

  useEffect(() => {
    const fecha_actual = new Date();
    let fecha_fin =
      MembresiaVigente[0].fecha_fin.substr(6, 4) +
      "/" +
      MembresiaVigente[0].fecha_fin.substr(3, 2) +
      "/" +
      MembresiaVigente[0].fecha_fin.substr(0, 2);
    const fecha_final = new Date(fecha_fin);
    if (!MembresiaVigente[0].estatus_conekta && fecha_actual <= fecha_final) {
      guardarRenovar(true);
      guardarCancelar(false);
      // guardarMembresiavigente(MembresiaVigente);
    }
    if (MembresiaVigente[0].estatus_conekta && fecha_actual <= fecha_final) {
      guardarRenovar(false);
      guardarCancelar(true);
      // guardarMembresiavigente(MembresiaVigente);
    }
    if (fecha_actual < fecha_final) {
      guardarModificar(true);
    }
    // }
  }, []);

  const cancelarMembresia = () => {
    // guardarMostrar(true);
    window.location.href = "cancelar-membresia/";
  };

  const modificarMembresia = () => {
    // guardarMostrarmodificar(true);
    window.location.href = "renovar-membresia/";
  };

  const reanudarMembresia = () => {
    // guardarMostrarreanudar(true);
    window.location.href = "reactivar-membresia/";
  };

  return (
    <>
      <div className="miperfil-contenedor">
        <div className="miperfil-renglon">
          <div className="miperfil-columnas pb5">
            <label className="miperfil-etiqueta-titulo">
              Cliente {Nivel}{" "}
              {MembresiaVigente.length > 0 && (
                <>
                  <img
                    src="/wp-content/uploads/sites/2/2021/08/grupo-886.png"
                    alt=""
                  />
                  {renovar && (
                    <span className="fan-mensaje-membresiacancelada">
                      Membresía cancelada
                    </span>
                  )}
                </>
              )}
            </label>
          </div>
          <div className="miperfil-columnas pb5 pi5">
            <div className="miperfil-renglon">
              {modificar && (
                <div className="btn-editar">
                  <i class="fa fa-edit">
                    <a href="#!" onClick={modificarMembresia}>
                      Modificar membresía
                    </a>
                  </i>
                </div>
              )}

              {cancelar && (
                <div className="btn-editar">
                  <i class="fa fa-edit">
                    <a href="#!" onClick={cancelarMembresia}>
                      {" "}
                      Cancelar membresía
                    </a>
                  </i>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="miperfil-renglon">
          {MembresiaVigente ? (
            <>
              <div className="miperfil-columnas pb5 pd5">
                <label className="miperfil-etiqueta-titulo">
                  Fecha de Adquisición:
                </label>
                <div className="miperfil-etiqueta-descripcion">
                  {MembresiaVigente[0].fecha_inicio}
                </div>
              </div>
              <div className="miperfil-columnas pb5 pi5">
                <label className="miperfil-etiqueta-titulo">
                  Seguirás disfrutando de los beneficios hasta el día:
                </label>
                <div className="miperfil-etiqueta-descripcion">
                  {MembresiaVigente[0].fecha_fin}
                </div>
                {renovar && (
                  <div className="fan-btn-reanudar">
                    <i class="fa fa-edit">
                      <a
                        href="#!"
                        onClick={reanudarMembresia}
                        className="fan-btn-color"
                      >
                        {" "}
                        Reanudar membresía
                      </a>
                    </i>
                  </div>
                )}
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default MiPerfilFan;
