import React, { useEffect } from "react";
import { Encrypt, Decrypt } from "../../Utils/EncryptDecrypt";
const Plan = ({ plan }) => {
  const { id, nombre, short_name, descripcion, costo, activo } = plan;

  const seleccionarPlan = (e) => {
    Encrypt("planSeleccionado", e.target.value);
  };

  return (
    <>
      <div
        className={`plan-col-3 plan-fondo ${
          id === 3 ? "plan-mensual" : id === 2 ? "plan-semestral" : "plan-anual"
        }`}
      >
        <div className="plan-titulo">{descripcion}</div>
        <div className="plan-descripcion">
          <input
            type="radio"
            id={short_name}
            name="plan"
            value={id}
            onChange={seleccionarPlan}
            // className={
            //   Decrypt("planrenovar") >= id
            //     ? "plan-radiobutton"
            //     : "plan-radiobutton disabled"
            // }
            className="plan-radiobutton"
          />
          <label htmlFor="">{short_name}</label>
          <br />
          <label htmlFor="">${costo}MXN</label>
        </div>
      </div>
    </>
  );
};

export default Plan;
