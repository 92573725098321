import React, { useState } from "react";
import clienteAxios from "../../config/axios";
import { Encrypt, Decrypt } from "../../Utils/EncryptDecrypt";
import { Button } from "@material-ui/core";
import "./ReanudarMembresia.css";
const ReanudarMembresiaError = () => {
  //   const { MembresiaVigente } = Decrypt("informacioncliente");

  //   const onClick = async (key) => {
  //     // let data = { plan: MembresiaVigente[0].plan_membresia };
  //     // console.log("plan:", MembresiaVigente[0].plan_membresia);
  //     // const url = "/api/membresia/" + key + "/reanudar";
  //     // await clienteAxios
  //     //   .put(url, data, {
  //     //     headers: {
  //     //       Authorization: "Bearer " + Decrypt("token"),
  //     //     },
  //     //   })
  //     //   .then((respuesta) => {
  //     //     console.log(respuesta.data);
  //     //   })
  //     //   .catch((error) => {
  //     //     console.log(
  //     //       "No puede iniciar sesión con las credenciales proporcionadas. Cliente Reanudar"
  //     //     );
  //     //     console.log(error);
  //     //   });
  //   };
  const actualizarMetodoPago = () => {
    window.location.href = "";
  };
  const cancelar = () => {
    window.location.href = "mi-perfil/";
  };
  const iraplan = () => {
    window.location.href = "plan/";
  };

  return (
    <>
      <div className="reanudar-membresia-contenedor">
        <div className="pb5 pd5">
          <label className="fan-etiqueta-titulo">
            ¿Deseas reactivar tu memebresía?
          </label>
          {/* {MembresiaVigente.map((membresia) => ( */}
          <div className="row modal-ptb-4vh">
            <label className="fan-etiqueta-descripcion">
              Al reactivar tu membresía tendrás acceso nuevamente a los
              beneficios. Puedes renovar tu membresía anterior, o subir de
              membresía paraacceder a más beneficios.
            </label>
            <div className="fan-alineacion-derecha">
              <Button
                variant="outlined"
                onClick={cancelar}
                className="fan-btn-noreactivar"
              >
                Cancelar
              </Button>
              <Button
                variant="outlined"
                onClick={actualizarMetodoPago}
                className="fan-btn-reactivar"
              >
                Actualizar método de pago
              </Button>
              <div className="btn-editar modalnr-btn-editar">
                O{" "}
                <a href="#!" onClick={iraplan}>
                  seleccionar un nuevo plan
                </a>
              </div>
            </div>
          </div>
          {/* ))} */}
        </div>
      </div>
    </>
  );
};

export default ReanudarMembresiaError;
