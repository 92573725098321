import { Switch } from "@material-ui/core";
import React, { useEffect, useState } from "react";
const MovimientosInicio = ({ movimiento }) => {
  const [src, guardarSrc] = useState("");
  console.log(movimiento);

  const { TipoMovimiento, Cantidad, Cantidad_Monedero } = movimiento;

  useEffect(() => {
    if (TipoMovimiento == "Compra Móvil") {
      guardarSrc("../wp-content/uploads/sites/2/2021/08/compra.png");
    }

    switch (TipoMovimiento) {
      case "Compra Móvil":
        guardarSrc("../wp-content/uploads/sites/2/2021/08/trazado-87.png");
        break;

      case "Compra":
        guardarSrc("../wp-content/uploads/sites/2/2021/08/compra.png");
        break;

      case "Compra QR":
        guardarSrc("../wp-content/uploads/sites/2/2021/08/grupo-313.png");
        break;

      default:
        guardarSrc("../wp-content/uploads/sites/2/2021/08/compra.png");
        break;
    }
  }, []);

  return (
    <>
      <div className="movimientos-listado">
        <div className="movimiento-listado-div-icono">
          <img src={src} alt="" className="movimientos-listado-icono" />
        </div>
        <div className="movimientos-listado-div-tipo">
          <div>
            <p className="movimientos-listado-tipo">{TipoMovimiento}</p>
          </div>
        </div>
        <div>
          <p className="movimientos-listado-precio">
            {Cantidad}
            <br />+{Cantidad_Monedero}
          </p>
        </div>
      </div>
    </>
  );
};

export default MovimientosInicio;
