import React, { useState } from "react";
import clienteAxios from "../../config/axios";
import { Encrypt, Decrypt } from "../../Utils/EncryptDecrypt";
import { Button } from "@material-ui/core";
import "./ReanudarMembresia.css";
// import Error from "../Error";
// import ModalNoreactivar from "./ModalNoreactivar";
const ReanudarMembresia = () => {
  const { MembresiaVigente } = Decrypt("informacioncliente");

  const onClick = async (key) => {
    let data = { plan: MembresiaVigente[0].plan_membresia };
    console.log("plan:", MembresiaVigente[0].plan_membresia);
    const url = "/api/membresia/" + key + "/reanudar";
    await clienteAxios
      .put(url, data, {
        headers: {
          Authorization: "Bearer " + Decrypt("token"),
        },
      })
      .then((respuesta) => {
        obtenerMembresia();
        window.location.href = "mi-perfil/";
      })
      .catch((error) => {
        console.log(
          "No puede iniciar sesión con las credenciales proporcionadas. Cliente Reanudar"
        );
        console.log(error);
      });
  };
  const cerrarModal = () => {
    window.location.href = "no-reanudar-membresia/";
  };

  const obtenerMembresia = async () => {
    await clienteAxios("/api/cliente/get_cliente/", {
      headers: {
        Authorization: "Bearer " + Decrypt("token"),
      },
    })
      .then((respuesta) => {
        sessionStorage.removeItem(Decrypt("informacioncliente"));
        Encrypt("informacioncliente", respuesta.data);
      })
      .catch((error) => {
        console.log(
          "No puede iniciar sesión con las credenciales proporcionadas."
        );
      });
  };

  return (
    <>
      <div className="reanudar-membresia-contenedor">
        <div className="pb5 pd5">
          {/* <label className="fan-etiqueta-titulo">
            ¿Deseas reactivar tu memebresía?
          </label> */}
          {MembresiaVigente.map((membresia) => (
            <div className="row modal-ptb-4vh">
              {/* <label className="fan-etiqueta-descripcion">
                Al reactivar tu membresía tendrás acceso nuevamente a los
                beneficios. Puedes renovar tu membresía anterior, o subir de
                membresía paraacceder a más beneficios.
              </label> */}
              <div className="fan-alineacion-derecha">
                <Button
                  variant="outlined"
                  onClick={cerrarModal}
                  className="fan-btn-noreactivar"
                >
                  No reactivar
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => onClick(membresia.id)}
                  className="fan-btn-reactivar"
                >
                  Reactivar
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ReanudarMembresia;
