import React from "react";
const Movimiento = ({ movimiento }) => {
  const {
    Caffenio,
    TipoMovimiento,
    Fecha,
    Saldo_Monedero,
    Saldo_Anterior,
    Cantidad,
  } = movimiento;

  return (
    <>
      <div className="row movimientos-descripcion-listado">
        <div className="col-md-3 caffenio-align">{Caffenio}</div>
        <div className="col-md-2 tipomovimiento-align">{TipoMovimiento}</div>
        <div className="col-md-2">
          {("0" + new Date(Fecha).getDate()).slice(-2) +
            "/" +
            ("0" + (new Date(Fecha).getMonth() + 1)).slice(-2) +
            "/" +
            new Date(Fecha).getFullYear()}
        </div>
        <div className="col-md-1 saldomonedero-align">{Saldo_Monedero}</div>
        <div className="col-md-2 saldoanterior-align">{Saldo_Anterior}</div>
        <div className="col-md-2 cantidad-align">{Cantidad}</div>
      </div>
    </>
  );
};

export default Movimiento;
