import React from "react";
import "./Sabor.css";
const SaborImagenes = ({ sabores, urlimagen, nombre, descripcion }) => {
  return (
    <>
      <div className="row sabor-contenedor">
        <div className="col-lg-4 col-md-5">
          <div id="migas-menu">
            <ul className="breadcrumb">
              <li class="breadcrumb-item">Menú</li>
              <li class="breadcrumb-item active">{nombre}</li>
            </ul>
          </div>
          <div className="sabor-producto-contenedor">
            <div className="sabor-producto-contenedor-interno">
              <div className="sabor-imagen">
                <img src={urlimagen} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-8 col-md-7 ">
          <div className="sabor-producto-titulo">{nombre}</div>
          <div className="sabor-producto-descripcion">{descripcion}</div>
          {sabores.length > 0 &&
            (
              <>
                <p className="sabores-producto">Sabores</p>
              </>
            )
          }
          <div className="row sabor-contenedor-productos">
            {sabores.map((sabor, key) => (
              <div className="col-md-3">
                <div className="sabor-producto-contenedor">
                  <div className="sabor-producto-contenedor-interno-titulo">
                    {/* <div className="sabor-imagen">
                  <img src={urlimagen} alt="" />
                </div> */}
                    <div className="sabor-titulo">{sabor.Nombre}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SaborImagenes;
