import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Encrypt, Decrypt } from "../Utils/EncryptDecrypt";
// import clienteAxios from "../config/axios";
import { clienteAxios, clientewpAxios } from "../Utils/axios";
import "./Inicio.css";
import MovimientosInicio from "../Movimientos/MovimientosInicio";
import Beneficios from "../Beneficios/Beneficios";

const Inicio = () => {
  const [usuario, guardarUsuario] = useState([]);
  const [membresiamensual, guardarMembresiamensual] = useState(null);
  const [membresiasemestral, guardarMembresiasemestral] = useState(null);
  const [membresiaanual, guardarMembresiaanual] = useState(null);
  const [movimientos, guardarMovimientos] = useState([]);
  const [membresia, guardarmembresia] = useState(false);
  const [error, guardarError] = useState("");
  const [beneficios, guardarBeneficios] = useState({
    Nivel: "",
    Membresia: 0,
  });
  const [verMasBeneficios, guardarVerMasBeneficios] = useState(false);
  const [publicidad, guardarPublicidad] = useState([]);

  const {
    id,
    idCuentaLealtad,
    Nombre,
    ApellidoP,
    Email,
    Telefono,
    Activo,
    Saldo,
    idTipoIntelicard,
    SaldoMonedero,
    Nivel,
  } = usuario;

  useEffect(() => {
    if (Decrypt("token") == null) {
      window.location.href = "inicio/";
    }

    guardarUsuario(Decrypt("usuario"));
    const obtenerMembresias = async () => {
      await clienteAxios("/api/membresia_plan/", {
        headers: {
          Authorization: "Bearer " + Decrypt("token"),
        },
      })
        .then((respuesta) => {
          console.log("MEMBRESIAS", respuesta);
          let arrayMembresias = respuesta.data.filter(
            (membresia) =>
              membresia.id === 1 || membresia.id === 2 || membresia.id === 3
          );
          guardarMembresiamensual(respuesta.data[2]);
          guardarMembresiasemestral(respuesta.data[1]);
          guardarMembresiaanual(respuesta.data[0]);
          Encrypt("membresias", arrayMembresias);
          Encrypt("membresiaMensual", respuesta.data[2]);
          Encrypt("membresiaSemestral", respuesta.data[1]);
          Encrypt("membresiaAnual", respuesta.data[0]);
        })
        .catch((error) => {
          console.log(
            "No puede iniciar sesión con las credenciales proporcionadas."
          );
        });
    };
    obtenerMembresias();
    const obtenerCliente = async () => {
      guardarBeneficios({
        Nivel: Decrypt("usuario").Nivel,
      });
      await clienteAxios("/api/cliente/get_cliente/", {
        headers: {
          Authorization: "Bearer " + Decrypt("token"),
        },
      })
        .then((respuesta) => {
          Encrypt("informacioncliente", respuesta.data);
          if (respuesta.data.MembresiaVigente.length > 0) {
            guardarmembresia(true);
            guardarBeneficios({
              Nivel: Decrypt("usuario").Nivel,
              Membresia: respuesta.data.MembresiaVigente[0].plan_membresia,
            });
          }
        })
        .catch((error) => {
          console.log(
            "No puede iniciar sesión con las credenciales proporcionadas."
          );
          console.log(error);
          guardarError(error);
        });
    };
    obtenerCliente();
    const obtenerMovimientos = async () => {
      const url = "wp-json/api/v1/obtener_publicidad";
      await clientewpAxios(url).then((response) => {
        console.log("Publicidad", response.data);
        guardarPublicidad(response.data);
      });

      await clienteAxios("/api/movimiento/get_movimientos_cliente/", {
        headers: {
          Authorization: "Bearer " + Decrypt("token"),
        },
      })
        .then((respuesta) => {
          Encrypt("movimientoscliente", respuesta.data);
          guardarMovimientos(respuesta.data);
          console.log("MVC", respuesta.data);
        })
        .catch((error) => {
          console.log(error);
          guardarError(error);
        });
    };
    obtenerMovimientos();
  }, []);

  const editarPerfil = () => {
    window.location.href = "micaffeniomiperfil/";
  };

  const btnVerMasBeneficios = () => {
    guardarVerMasBeneficios(true);
  };

  console.log("publicidad", publicidad);
  let Publicidad_a;
  let LabelTitulo;
  let LabelPrecio;
  let BienvenidaPublicidad;

  if (publicidad.length > 0) {

    BienvenidaPublicidad = styled.div`
    background-color: ${publicidad[0].colorfondocontenedor};
    display: flex;
    flex: 39%;
    border-radius: 0px 15px 15px 0px;
    `;

    Publicidad_a = styled.a`
    background-color: ${publicidad[0].colorfondoboton};
    color: ${publicidad[0].colortexto};  
    padding: 0.5vh 2vw;
    border-radius: 10px;
    border: 1px solid ${publicidad[0].colorfondoboton};
    
    &:hover{
      background-color: ${publicidad[0].colorfondohoverboton};
      color: ${publicidad[0].colortextohover};  
      border: 1px solid ${publicidad[0].colorfondohoverboton};
    }
     
    `;

    LabelTitulo = styled.label`
      /*  background-color: ${publicidad[0].colorfondoboton};
      color: ${publicidad[0].colordescripciontexto};  
      padding: 1vh 2vw;
      border-radius: 10px;
      border: 1px solid ${publicidad[0].colorfondoboton}; */
      font-family: Brandon Grotesque Web;
      font-weight: 900;
      font-size: 2.2916666666666665VW;
      
    `;

    LabelPrecio = styled.label`
    /* background-color: ${publicidad[0].colorfondoboton};
    color: ${publicidad[0].colordescripciontexto};  
    padding: 1vh 2vw;
    border-radius: 10px;
    border: 1px solid ${publicidad[0].colorfondoboton}; */
    font-family: Brandon Grotesque Web;
    font-weight: 900;
    font-size: 1.7708333333333333VW;
    `;

  }

  return (
    <>
      {verMasBeneficios && (
        <Beneficios
          beneficios={beneficios}
          membresiaMensual={membresiamensual}
          membresiaSemestral={membresiasemestral}
          membresiaAnual={membresiaanual}
          guardarVerMasBeneficios={guardarVerMasBeneficios}
        />
      )}
      <div className="contenedor">
        {/* {error ? <Error mensaje={error} /> : null} */}
        <div className="bienvenida">
          <div className="bienvenida-contenedor-info">
            <div className="bienvenida-info">
              <div className="foto">
                {/* <img src="../wp-content/uploads/2021/10/grupo-392.png" alt="" /> */}
                <img src="../wp-content/uploads/2021/12/nivel-hola.png" alt="" />
                
              </div>
              <div className="contenedor-txt">
                <div className="txt-bienvenida">Bienvenida, {Nombre}</div>
                <div className="txt-cuenta">CTA {idCuentaLealtad}</div>
                <div className="btn-editar">
                  <i class="fa fa-edit">
                    {" "}
                    <a href="#!" onClick={editarPerfil}>
                      Ver perfil
                    </a>
                  </i>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="contenedor-publicidad">
              {publicidad.length > 0 && (
                  <div className="row">
                    <div className="col-md-5 publicidad-img">
                      <img src={publicidad[0].imagen} alt="" />
                    </div>
                    <div className="col-md-7 center vertical-center">
                      <div>
                        <LabelTitulo>{publicidad[0].descripcion}</LabelTitulo>
                        <LabelPrecio>${publicidad[0].precio}</LabelPrecio>
                        <div>
                          <Publicidad_a
                            href={publicidad[0].url}
                            target="_blank"
                          >
                            {publicidad[0].descripcion_boton}
                          </Publicidad_a>
                        </div>
                      </div>
                    </div>
                  </div>
              )}
            </div>
          </div>
        </div>
        <div className="info">
          <div className="info-columna">
            <div className="invitacion-tienda">
              <div className="contenedor-img">
                <img
                  src="../wp-content/uploads/sites/2/2021/08/enmascarar-grupo-12.jpg"
                  alt=""
                />
              </div>
              <div className="txt-invitacion">Visita la tienda en línea</div>
            </div>
            <div className="nivel-info">
              <div className="txt-nivel-amigo">
                Nivel {Nivel}{" "}
                {membresia && (
                  <img
                    src="/wp-content/uploads/sites/2/2021/08/grupo-886.png"
                    alt=""
                  />
                )}
              </div>
              <div>
                <hr className="division" />
              </div>
              <div className="txt-beneficios-movimientos">
                Tus beneficios actuales
              </div>
              <div className="txt-nivel-monedero">
                {/* Obtienes el 2% de monedero por cada compra. */}
                {beneficios.Membresia &&
                  beneficios.Membresia == 1 &&
                  membresiaanual.promociones[0].beneficio}
                {beneficios.Membresia &&
                  beneficios.Membresia == 2 &&
                  membresiasemestral.promociones[0].beneficio}
                {beneficios.Membresia &&
                  beneficios.Membresia == 3 &&
                  membresiamensual.promociones[0].beneficio}
                {!beneficios.Membresia &&
                  beneficios.Nivel == "Monedero HOLA" &&
                  "2% de Reembolso acumulable y canjeable"}
                {!beneficios.Membresia &&
                  beneficios.Nivel == "Monedero AMIGO" &&
                  "4% Reembolso acumulable y canjeable"}
                {!beneficios.Membresia &&
                  beneficios.Nivel == "Monedero FAN" &&
                  "8% Reembolso acumulable y canjeable"}
              </div>
              <a href="#!" onClick={btnVerMasBeneficios}>
                <div className="txt-nivel-vermas">Ver más</div>
              </a>
              <div>
                <hr className="division" />
              </div>
              <div className="txt-nivel-plus">¡Dale un plus a tu nivel!</div>
              <a href="http://35.239.108.106/micaffenio/membresia/">
                <div className="txt-nivel-obtenerinfo">Obtener información</div>
              </a>
            </div>
          </div>
          <div className="movimientos">
            <div className="txt-beneficios-movimientos center">
              Últimos movimientos
            </div>
            <div className="movimientos-descripcion center">
              Aquí puedes encontrar tus movimientos. Haz clic para ver detalles.
            </div>
            {movimientos ? (
              <>
                {movimientos.slice(0, 6).map((movimiento) => (
                  <MovimientosInicio movimiento={movimiento} />
                ))}
                <a href="http://35.239.108.106/micaffenio/movimientos/">
                  <div className="movimientos-vermas center">
                    Ver más movimientos
                  </div>
                </a>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Inicio;
