import React, { useState, useEffect } from "react";
import { Encrypt, Decrypt } from "../../Utils/EncryptDecrypt";
import "./Final.css";
const Final = () => {
  const [arrayMembresias, guardarArrayMembresias] = useState([]);
  const [promociones, guardarPromociones] = useState([]);

  useEffect(() => {
    const planseleccionado = Decrypt("planSeleccionado");
    const planes = Decrypt("membresias");
    console.log(planseleccionado);
    const array = planes.filter(
      (membresia) => membresia.id == planseleccionado
    );
    guardarArrayMembresias(array[0]);
    guardarPromociones(array[0].promociones);
  }, []);
  console.log(promociones);

  return (
    <>
      <div className="final-titulo">
        <label htmlFor="">¡Ya eres miembro!</label>
      </div>
      <div className="final-descripcion">
        <p>Gracias por adquirir nuestro servicio de membresías.</p>
        <p>
          Recuerda que este mes es una prueba gratuita que se renovará
          automáticamente el día{" "}
        </p>
      </div>
      <div className="final-listado">
        {promociones.map((promocion) => (
          <div> {promocion.beneficio} </div>
        ))}
      </div>
    </>
  );
};

export default Final;
