import React, { useEffect, useState } from "react";
import "./Productos.css";
import Clasificacion from "./Clasificacion";
const MenuProductos = ({ menus, clasificaciones, sabores, presentaciones }) => {
  const [productos, guardarProductos] = useState([]);
  useEffect(() => {
    const concatenarArreglos = () => {
      let res = [];
      let resSabores = [];
      let flavors = [];
      let resPresentaciones = [];
      let presentations = [];
      for (let m = 0; m < menus.length; m++) {
        res = clasificaciones.filter(
          (clasificacion) =>
            clasificacion.ClasificacionId == menus[m].ClassificationId
        );
        if (res.length > 0) {
          menus[m].NombreClasificacion = res[0].Nombre;
          menus[m].UrlImagen = res[0].UrlImagen;
          menus[m].UrlIcon = res[0].UrlIcon;
          menus[m].DescriptionApp = res[0].DescriptionApp;
        }
        flavors = [];
        for (let f = 0; f < menus[m].Flavors.length; f++) {
          resSabores = sabores.filter(
            (sabor) => sabor.TipoSaborId == menus[m].Flavors[f]
          );
          if (resSabores.length > 0) {
            let obj = {
              TipoSaborId: resSabores[0].TipoSaborId,
              Nombre: resSabores[0].Nombre,
            };
            flavors.push(obj);
          }
        }
        presentations = [];
        for (let p = 0; p < menus[m].Presentations.length; p++) {
          resPresentaciones = presentaciones.filter(
            (presentaciones) => presentaciones.PresentacionId == menus[m].Presentations[p]
          );
          if (resPresentaciones.length > 0) {
            let obj = {
              PresentacionID: resPresentaciones[0].PresentacionID,
              Nombre: resPresentaciones[0].Nombre,
              UrlIcon: resPresentaciones[0].UrlIcon
            };
            presentations.push(obj);
          }
        }
        menus[m].Presentaciones = presentations;
        menus[m].Sabores = flavors;
      }
      guardarProductos(menus);
    };
    concatenarArreglos();
  }, []);

  //Lop hizo Froi
  // useEffect(() => {
  //   const concatenarArreglos = () => {
  //     let res = [];
  //     let resSabores = [];
  //     let flavors = [];
  //     let resPresentaciones = [];
  //     let presentations = [];
  //     for (let m = 0; m < menus.length; m++) {
  //       res = clasificaciones.filter(
  //         (clasificacion) =>
  //           clasificacion.ClasificacionId == menus[m].ClassificationId
  //       );
  //       if (res.length > 0) {
  //         menus[m].NombreClasificacion = res[0].Nombre;
  //         menus[m].UrlImagen = res[0].UrlImagen;
  //         menus[m].UrlIcon = res[0].UrlIcon;
  //         menus[m].DescriptionApp = res[0].DescriptionApp;
  //       }
  //       flavors = [];
  //       for (let f = 0; f < menus[m].Flavors.length; f++) {
  //         resSabores = sabores.filter(
  //           (sabor) => sabor.TipoSaborId == menus[m].Flavors[f]
  //         );
  //         if (resSabores.length > 0) {
  //           let obj = {
  //             TipoSaborId: resSabores[0].TipoSaborId,
  //             Nombre: resSabores[0].Nombre,
  //           };
  //           flavors.push(obj);
  //         }
  //       }
  //       for (let p = 0; p < menus[m].Presentations.length; p++) {
  //         resPresentaciones = presentaciones.filter(
  //           (presentaciones) => presentaciones.PresentacionId == menus[m].Presentations[p]
  //         );
  //         if (resPresentaciones.length > 0) {
  //           let obj = {
  //             PresentacionID: resPresentaciones[0].PresentacionID,
  //             Nombre: resPresentaciones[0].Nombre,
  //             UrlIcon: resPresentaciones[0].UrlIcon
  //           };
  //           presentations.push(obj);
  //         }
  //       }
  //       menus[m].Sabores = flavors;
  //       console.log('menus[m] ',menus[m]);
  //     }
  //     guardarProductos(menus);
  //   };
  //   concatenarArreglos();
  // }, []);

  return (
    <>
      <Clasificacion productos={productos} />
    </>
  );
};

export default MenuProductos;
