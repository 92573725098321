import React, { useState } from "react";
import Drive from "./Drive";
const Drives = ({ id, nombre, drive, guardarCoordenadas, colocarPosicion }) => {
  const [visible, guardarVisible] = useState(false);

  const onClick = () => {
    if (visible) {
      guardarVisible(false);
    } else {
      guardarVisible(true);
    }
  };

  return (
    <div>
      <div>
        <div className="padre">
          {/* {
            tipo === "1" ? (
              <img
                className="icondrives"
                src="../wp-content/uploads/2021/05/drive_rojo.png"
                alt=""
              />
            ) : (
              <img
                className="icondrives"
                src="../wp-content/uploads/2021/05/drive_amarillo.png"
                alt=""
              />
            )
          } */}

          <label className="drives" onClick={onClick}>
            {nombre}
          </label>
        </div>
        <div className={visible ? "visible caja-info-drives" : "no-visible"}>
          <Drive
            drive={drive}
            // drive={drives.filter((drive) => drive.id === id)}
            guardarCoordenadas={guardarCoordenadas}
            colocarPosicion={colocarPosicion}
          />
        </div>
      </div>
    </div>
  );
};

export default Drives;
