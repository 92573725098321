import React, { useState, useEffect } from "react";
import clienteAxios from "../../config/axios";
import { Encrypt, Decrypt } from "../../Utils/EncryptDecrypt";
import "./Confirmacion.css";
import ModalCambiarDireccion from "../../Modal/ModalCambiarDireccion";
import Error from "../../Error";
import Spinner from "./Spinner";
const Confirmacion = () => {
  const [informaciontarjeta, guardarInformaciontarjeta] = useState(null);
  const [arrayMembresias, guardarArrayMembresias] = useState([]);
  const [fechaactual, guardarFechaactual] = useState(null);
  const [fechavigencia, guardarFechavigencia] = useState(null);
  const [Direcciones, guardarDirecciones] = useState([]);
  const [planSeleccionado, guardarPlanSeleccionado] = useState(null);
  const [direccionseleccionada, guardarDireccionseleccionada] = useState([]);
  const [mostrar, guardarMostrar] = useState(false);
  const [error, guardarError] = useState("");
  const [mostrarspinner, guardarMostrarspinner] = useState(false);

  useEffect(() => {
    guardarInformaciontarjeta(Decrypt("informaciontarjeta"));
    const planseleccionado = Decrypt("planSeleccionado");
    const planes = Decrypt("membresias");
    const informacioncliente = Decrypt("informacioncliente");
    guardarDirecciones(informacioncliente.Direcciones);
    guardarPlanSeleccionado(planseleccionado);

    const array = planes.filter(
      (membresia) => membresia.id == planseleccionado
    );
    guardarArrayMembresias(array[0]);

    const vigencia = () => {
      let today = new Date();
      let vigencia;

      guardarFechaactual(
        ("0" + today.getDate()).slice(-2) +
          "-" +
          ("0" + (today.getMonth() + 1)).slice(-2) +
          "-" +
          today.getFullYear()
      );
      if (planseleccionado == 3) {
        vigencia = new Date(today.setMonth(today.getMonth() + 1));
        guardarFechavigencia(
          ("0" + vigencia.getDate()).slice(-2) +
            "-" +
            ("0" + (vigencia.getMonth() + 1)).slice(-2) +
            "-" +
            vigencia.getFullYear()
        );
      } else if (planseleccionado == 2) {
        vigencia = new Date(today.setMonth(today.getMonth() + 6));
        guardarFechavigencia(
          ("0" + vigencia.getDate()).slice(-2) +
            "-" +
            ("0" + (vigencia.getMonth() + 1)).slice(-2) +
            "-" +
            vigencia.getFullYear()
        );
      } else {
        vigencia = new Date(today.setFullYear(today.getFullYear() + 1));
        guardarFechavigencia(
          ("0" + vigencia.getDate()).slice(-2) +
            "-" +
            ("0" + (vigencia.getMonth() + 1)).slice(-2) +
            "-" +
            vigencia.getFullYear()
        );
      }
    };
    vigencia();
  }, []);

  const { titular, vencimiento, notarjeta, ccv } =
    Decrypt("informaciontarjeta");

  const {
    id,
    cliente,
    direccion,
    estado,
    municipio,
    colonia,
    calle,
    calle_opcional,
    codigo_postal,
  } = Direcciones;

  const { short_name } = arrayMembresias;

  const seleccionarDireccion = () => {
    guardarMostrar(true);
  };

  const successResponseHandler = async (token) => {
    console.log("Token ", token);
    console.log("Plan Seleccionado ", planSeleccionado);
    let id;
    if (Object.keys(direccionseleccionada).length > 0) {
      id = direccionseleccionada.id;
      console.log("ID local ", id);
    } else {
      let direccionprincipal = Direcciones.filter(
        (direccion) => direccion.principal === true && direccion.activo === true
      );
      id = direccionprincipal[0].id;
      console.log("ID local principal", id);
    }
    const data = {
      plan: planSeleccionado,
      perfil_direccion: id,
      tarjeta: {
        token_id: token.id,
        type: "card",
      },
    };

    await clienteAxios
      .post("/api/membresia/", data, {
        headers: {
          Authorization: "Bearer " + Decrypt("token"),
        },
      })
      .then((respuesta) => {
        console.log(respuesta.data);
        guardarMostrarspinner(false);
      })
      .catch((error) => {
        guardarMostrarspinner(false);
        console.log(
          "No puede iniciar sesión con las credenciales proporcionadas."
        );
        console.log(error);
        guardarError(error);
      });

    window.location.href = "finalizacion/";
  };

  const errorResponseHandler = (error) => {
    console.log("Error ", error.message);
    guardarMostrarspinner(false);
    guardarError(error);
  };

  const tokenizar = () => {
    guardarMostrarspinner(true);
    window.Conekta.setPublicKey("key_gTzHLZ7apzKop5KPLsGP1Q");
    let calle;
    let colonia;
    let municipio;
    let estado;
    let codigo_postal;
    if (Object.keys(direccionseleccionada).length > 0) {
      calle = direccionseleccionada.calle;
      colonia = direccionseleccionada.colonia;
      municipio = direccionseleccionada.municipio;
      estado = direccionseleccionada.estado;
      codigo_postal = direccionseleccionada.codigo_postal;
    }
    let tokenParams = {
      card: {
        number: notarjeta,
        name: titular,
        exp_year: "20" + vencimiento.substr(3, 2),
        exp_month: vencimiento.substr(0, 2),
        cvc: ccv,
        address: {
          street1: calle,
          street2: colonia,
          city: municipio,
          state: estado,
          zip: codigo_postal,
          country: "Mexico",
        },
      },
    };
    console.log("tokenparams ", tokenParams);
    window.Conekta.Token.create(
      tokenParams,
      successResponseHandler,
      errorResponseHandler
    );
  };

  return (
    <>
      {/* <Spinner /> */}
      {mostrarspinner && <Spinner />}
      {error ? <Error mensaje={error} /> : null}
      {mostrar && (
        <ModalCambiarDireccion
          Direcciones={Direcciones}
          guardarMostrar={guardarMostrar}
          guardarDireccionseleccionada={guardarDireccionseleccionada}
        />
      )}
      <div className="row">
        <div className="col-md-6">
          <div className="confirmacion-titulo">Titular de la tarjeta</div>
          <div className="confirmacion-descripcion">{titular}</div>
          <div className="confirmacion-titulo">Método de pago</div>
          <div className="confirmacion-descripcion">Tarjeta</div>
          <div className="confirmacion-titulo">Fecha de inicio</div>
          <div className="confirmacion-descripcion">{fechaactual}</div>
          <div className="confirmacion-titulo">Direccion</div>
          <div className="confirmacion-descripcion">
            {direccionseleccionada.length === 0
              ? Direcciones.filter(
                  (direccion) =>
                    direccion.principal === true && direccion.activo === true
                ).map((direccionPrincipal) => (
                  <div className="confirmacion-descripcion">
                    {direccionPrincipal.calle} #{direccionPrincipal.numero}{" "}
                    entre {direccionPrincipal.calle_opcional} <br />
                    Colonia {direccionPrincipal.colonia}{" "}
                    {direccionPrincipal.municipio}, {direccionPrincipal.estado}
                    <br />
                    {" CP. "} {direccionPrincipal.codigo_postal}
                  </div>
                ))
              : Direcciones.filter(
                  (direccion) => direccion.id === direccionseleccionada.id
                ).map((direccionPrincipal) => (
                  <div className="confirmacion-descripcion">
                    {direccionPrincipal.calle} #{direccionPrincipal.numero}{" "}
                    entre {direccionPrincipal.calle_opcional} <br />
                    Colonia {direccionPrincipal.colonia}{" "}
                    {direccionPrincipal.municipio}, {direccionPrincipal.estado}
                    <br />
                    {" CP. "} {direccionPrincipal.codigo_postal}
                  </div>
                ))}
            <div className="confirmacion-contenedor-boton">
              <a
                href="#!"
                onClick={seleccionarDireccion}
                className="confirmacion-boton confirmacion-boton-blanco"
              >
                Cambiar
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="confirmacion-titulo">Plan Seleccionado</div>
          <div className="confirmacion-descripcion">{short_name}</div>
          <div className="confirmacion-titulo">Válido hasta</div>
          <div className="confirmacion-descripcion">{fechavigencia}</div>
        </div>
      </div>
      <div className="confirmacion-contenedor-boton">
        <a
          href="#!"
          onClick={tokenizar}
          className="confirmacion-boton  confirmacion-absolute confirmacion-boton-rojo"
        >
          Siguiente
        </a>
      </div>
    </>
  );
};

export default Confirmacion;
