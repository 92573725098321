import React, { useState } from "react";
import { Encrypt, Decrypt } from "../../Utils/EncryptDecrypt";
import "./CapturaTarjeta.css";
const CapturaTarjeta = () => {
  const [tarjeta, guardarTarjeta] = useState({
    titular: "",
    vencimiento: "",
    notarjeta: "",
    ccv: "",
  });

  const obtenerInformacion = (e) => {
    guardarTarjeta({
      ...tarjeta,
      [e.target.name]: e.target.value,
    });
  };

  const onBlur = () => {
    Encrypt("informaciontarjeta", tarjeta);
  };

  const { titular, vencimiento, notarjeta, ccv } = tarjeta;

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div>
            <label htmlFor="" className="ct-titulo">
              Titular de la tarjeta
            </label>
            <input
              type="text"
              className="ct-input"
              placeholder="Nombre completo"
              id="titular"
              name="titular"
              value={titular}
              onChange={obtenerInformacion}
              onBlur={onBlur}
            />
          </div>
          <div className="ct-espacio-p4">
            <label htmlFor="" className="ct-titulo">
              Tarjeta <i className="fa fa-credit-card"></i>
            </label>
            <input
              type="text"
              maxLength="16"
              className="ct-input"
              placeholder="0011223344556677"
              id="notarjeta"
              name="notarjeta"
              value={notarjeta}
              onChange={obtenerInformacion}
              onBlur={onBlur}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div>
            <label htmlFor="" className="ct-titulo">
              Vencimiento <i className="fa fa-question-circle ct-rojo"></i>
            </label>
            <input
              type="text"
              maxLength="5"
              className="ct-input"
              placeholder="12/21"
              id="vencimiento"
              name="vencimiento"
              value={vencimiento}
              onChange={obtenerInformacion}
              onBlur={onBlur}
            />
          </div>
          <div className="ct-espacio-p4">
            <label htmlFor="" className="ct-titulo">
              CCV <i className="fa fa-question-circle ct-rojo"></i>
            </label>
            <input
              type="password"
              className="ct-input"
              maxLength="4"
              placeholder="***"
              required
              pattern="0000"
              id="ccv"
              name="ccv"
              value={ccv}
              onChange={obtenerInformacion}
              onBlur={onBlur}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CapturaTarjeta;
