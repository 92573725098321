import React, { useState, useEffect } from "react";
import { Encrypt, Decrypt } from "../../Utils/EncryptDecrypt";
import Plan from "./Plan";
import ModalMembresiaDireccion from "../../Modal/ModalMembresiaDireccion";
import "./Plan.css";
const SeleccionarPlan = () => {
  const [planes, guardarPlanes] = useState(null);
  const [mostrarmodal, guardarMostrarmodal] = useState(false);

  useEffect(() => {
    const planes = Decrypt("membresias");
    const planesordenado = planes.sort((a, b) => b.id - a.id);
    guardarPlanes(planesordenado);

    // guardarMostrarmodal(true);
  }, []);

  return (
    <>
      {/* {mostrarmodal && (
        <ModalMembresiaDireccion guardarMostrarmodal={guardarMostrarmodal} />
      )} */}
      <div className="row">
        {planes && planes.map((plan, key) => <Plan plan={plan} />)}
      </div>
    </>
  );
};

export default SeleccionarPlan;
