import React, { useState, useEffect } from "react";
import { Encrypt, Decrypt } from "../Utils/EncryptDecrypt";
import clienteAxios from "../config/axios";
import "./Inicio.css";
import MovimientosInicio from "../Movimientos/MovimientosInicio";

const InicioMembresia = () => {
  const [usuario, guardarUsuario] = useState([]);
  const [membresiamensual, guardarMembresiamensual] = useState(null);
  const [membresiasemestral, guardarMembresiasemestral] = useState(null);
  const [membresiaanual, guardarMembresiaanual] = useState(null);
  const [movimientos, guardarMovimientos] = useState([]);
  const [membresia, guardarmembresia] = useState(false);
  const [error, guardarError] = useState("");

  useEffect(() => {
    if (Decrypt("token") == null) {
      window.location.href = "inicio/";
    }

    guardarUsuario(Decrypt("usuario"));

    const obtenerMembresias = async () => {
      const respuesta = await clienteAxios("/api/membresia_plan/", {
        headers: {
          Authorization: "Bearer " + Decrypt("token"),
        },
      })
        .then((respuesta) => {
          let arrayMembresias = respuesta.data.filter(
            (membresia) =>
              membresia.id === 1 || membresia.id === 2 || membresia.id === 3
          );
          guardarMembresiamensual(respuesta.data[2]);
          guardarMembresiasemestral(respuesta.data[1]);
          guardarMembresiaanual(respuesta.data[0]);
          Encrypt("membresias", arrayMembresias);
          Encrypt("membresiaMensual", respuesta.data[2]);
          Encrypt("membresiaSemestral", respuesta.data[1]);
          Encrypt("membresiaAnual", respuesta.data[0]);
        })
        .catch((error) => {
          console.log(
            "No puede iniciar sesión con las credenciales proporcionadas."
          );
        });
    };
    obtenerMembresias();
    const obtenerCliente = async () => {
      await clienteAxios("/api/cliente/get_cliente/", {
        headers: {
          Authorization: "Bearer " + Decrypt("token"),
        },
      })
        .then((respuesta) => {
          Encrypt("informacioncliente", respuesta.data);
          if (respuesta.data.MembresiaVigente.length > 0) {
            guardarmembresia(true);
          }
        })
        .catch((error) => {
          console.log(
            "No puede iniciar sesión con las credenciales proporcionadas."
          );
          console.log(error);
          guardarError(error);
        });
    };
    obtenerCliente();
    const obtenerMovimientos = async () => {
      await clienteAxios("/api/movimiento/get_movimientos_cliente/", {
        headers: {
          Authorization: "Bearer " + Decrypt("token"),
        },
      })
        .then((respuesta) => {
          Encrypt("movimientoscliente", respuesta.data);
          guardarMovimientos(respuesta.data);
          console.log(respuesta.data);
        })
        .catch((error) => {
          console.log(error);
          guardarError(error);
        });
    };
    obtenerMovimientos();
  }, []);

  const {
    id,
    idCuentaLealtad,
    Nombre,
    ApellidoP,
    Email,
    Telefono,
    Activo,
    Saldo,
    idTipoIntelicard,
    SaldoMonedero,
    Nivel,
  } = usuario;

  const editarPerfil = () => {
    window.location.href = "miperfiledicion/";
  };

  return (
    <div className="contenedor">
      {/* {error ? <Error mensaje={error} /> : null} */}
      <div className="bienvenida">
        <div className="bienvenida-contenedor-info">
          <div className="bienvenida-info">
            <div className="foto">
              <img
                src="../wp-content/uploads/sites/2/2021/08/Trazado-371.jpg"
                alt=""
              />
            </div>
            <div className="contenedor-txt">
              <div className="txt-bienvenida">Bienvenida, {Nombre}</div>
              <div className="txt-cuenta">CTA {idCuentaLealtad}</div>
              <div className="btn-editar">
                <i class="fa fa-edit">
                  {" "}
                  <a href="#!" onClick={editarPerfil}>
                    Editar perfil
                  </a>
                </i>
              </div>
            </div>
          </div>
        </div>
        <div className="bienvenida-promo">
          <div className="">
            <img
              src="../wp-content/uploads/sites/2/2021/08/vaso.jpg"
              alt=""
              className="bienvenida-promo-img"
            />
          </div>
          <div>
            <div className="txt-vaso">Vaso Velvet</div>
            <div className="txt-precio">$349.00 MXN</div>
            <input
              type="submit"
              className="form-control btn-promo"
              value="¡Comprar ahora!"
            />
          </div>
        </div>
      </div>
      <div className="info">
        <div className="info-columna">
          <div className="invitacion-tienda">
            <div className="contenedor-img">
              <img
                src="../wp-content/uploads/sites/2/2021/08/enmascarar-grupo-12.jpg"
                alt=""
              />
            </div>
            <div className="txt-invitacion">Visita la tienda en línea</div>
          </div>
          <div className="nivel-info">
            <div className="txt-nivel-amigo">
              Nivel {Nivel}{" "}
              {membresia && (
                <img
                  src="/wp-content/uploads/sites/2/2021/08/grupo-886.png"
                  alt=""
                />
              )}
            </div>
            <div>
              <hr className="division" />
            </div>
            <div className="txt-beneficios-movimientos">
              Tus beneficios actuales
            </div>
            <div className="txt-nivel-monedero">
              Obtienes el 2% de monedero por cada compra.
            </div>
            <div className="txt-nivel-vermas">Ver más</div>
            <div>
              <hr className="division" />
            </div>
            <div className="txt-nivel-plus">¡Dale un plus a tu nivel!</div>
            <div className="txt-nivel-obtenerinfo">Obtener información</div>
          </div>
        </div>
        <div className="movimientos">
          <div className="txt-beneficios-movimientos center">
            Últimos movimientos
          </div>
          <div className="movimientos-descripcion center">
            Aquí puedes encontrar tus movimientos. Haz clic para ver detalles.
          </div>
          {movimientos ? (
            <>
              {movimientos.slice(0, 6).map((movimiento) => (
                <MovimientosInicio movimiento={movimiento} />
              ))}
              <div className="movimientos-vermas center">
                Ver más movimientos
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default InicioMembresia;
