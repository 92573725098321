import React, { useState } from "react";
import Drives from "./Drives";
const ListadoMunicipio = ({
  nombreMunicipio,
  nombreEstado,
  drives,
  guardarCoordenadas,
  colocarPosicion,
}) => {
  const [visible, guardarVisible] = useState(false);

  const onClick = () => {
    if (visible) {
      guardarVisible(false);
    } else {
      guardarVisible(true);
    }
  };

  return (
    <div>
      <div className="ciudades">
        <div className="row">
          <div className="col-md-10">
            <label className="ciudades" onClick={onClick}>
              {nombreMunicipio}, {nombreEstado}
            </label>
          </div>
          {/* <div className="col-md-2">
            {visible ? (
              <img
                src="../wp-content/uploads/2021/05/abierto.png"
                className="imgAbierto"
              />
            ) : (
              <img
                src="../wp-content/uploads/2021/05/cerrado.png"
                className="imgCerrado"
              />
            )}
          </div> */}
        </div>
        <div className={visible ? "visible" : "no-visible"}>
          {drives.map((drive, key) => (
            <Drives
              nombre={drive.nombre_drive}
              id={Drives.id}
              drive={drive}
              guardarCoordenadas={guardarCoordenadas}
              colocarPosicion={colocarPosicion}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ListadoMunicipio;
