import React, { useState, useEffect } from "react";
import clienteAxios from "../config/axios";
import { Decrypt } from "../Utils/EncryptDecrypt";
import Producto from "./Producto";
import SaborImagenes from "./SaborImagenes";

const Clasificacion = ({ productos }) => {
  const [clicked, guardarClicked] = useState(false);
  const onClick = (indexlocal) => {
    // console.log("IL ", indexlocal);
    // console.log("CK ", clicked);
    // console.log("Clicked no es igual a index local");
    guardarClicked(indexlocal);
  };
  // console.log(productos);
  return (
    <div className='productos '>
      {productos.map((producto, index) => (
        <div className='productos-list'>
          <div className="ciudades">
            <div className="row">
              <div className="col-md-10 text-center">
                <label className={clicked != index ? "lista-productos" : "lista-productos active"} onClick={() => onClick(index)}>
                  {producto.NombreClasificacion}
                </label>
              </div>
            </div>
          </div>
          {clicked == index &&(
            <SaborImagenes
            sabores={producto.Sabores}
            urlimagen={producto.UrlImagen}
            nombre={producto.NombreClasificacion}
            descripcion={producto.DescriptionApp}
            />
            )}
        </div>
      ))}
    </div>
  );
};

export default Clasificacion;
