import React, { useState } from "react";
import Drives from "./Drives";
const ListadoMunicipio = ({
  nombreLugar,
  drives,
  guardarCoordenadas,
  ChangeView
}) => {
  const [visible, guardarVisible] = useState(false);

  const onClick = () => {
    if (visible) {
      guardarVisible(false);
    } else {
      guardarVisible(true);
    }
  };

  return (
    <div>
      <div className="ciudades">
        <div className="row">
          <div className={visible ? "togged-abierto col-md-10" : "togged-cerrado col-md-10"} onClick={onClick}>
            <label className="ciudades">
              {nombreLugar}
            </label>
          </div>
          {/* <div className="col-md-2">
            {visible ? (
              <img
                src="../wp-content/uploads/2021/05/abierto.png"
                className="imgAbierto"
              />
            ) : (
              <img
                src="../wp-content/uploads/2021/05/cerrado.png"
                className="imgCerrado"
              />
            )}
          </div> */}
        </div>
        <div className={visible ? "visible" : "no-visible"}>
          {
            drives.map((drive, key) => (
              <Drives
                nombre={drive.DriveName}
                drive={drive}
                guardarCoordenadas={guardarCoordenadas}
                ChangeView={ChangeView}
              />
              ))}
        </div>
      </div>
    </div>
  );
};

export default ListadoMunicipio;
