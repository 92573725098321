import React, { useEffect, useState } from "react";
import { Pagination } from "semantic-ui-react";
import { Decrypt } from "../Utils/EncryptDecrypt";
import "./Movimientos.css";
import Movimiento from "./Movimiento";
const Movimientos = () => {
  const [movimientos, guardarMovimientos] = useState([]);
  const [paginaActual, guardarPaginaActual] = useState(1);
  const [registrosPorPagina, guardarRegistrosPorPagina] = useState(5);
  // const [totalPaginas, guardarTotalPaginas] = useState(0);

  useEffect(() => {
    guardarMovimientos(Decrypt("movimientoscliente"));
  }, []);

  const totalPaginas = Math.ceil(movimientos.length / 5);
  const indiceUltimoRegistro = paginaActual * registrosPorPagina;
  const indicePrimerRegistro = indiceUltimoRegistro - registrosPorPagina;

  const handlePaginationChange = (e, { activePage }) => {
    guardarPaginaActual(activePage);
  };

  return (
    <>
      <div className="movimientos-contenedor">
        <div className="movimientos-titulos">
          <div className="movimientos-titulo">Últimos Movimientos</div>
          <div className="movimientos-descripcion">
            Aquí puedes encontrar tus movimientos. Haz clic para ver detalles.
          </div>
        </div>
        <div className="row movimientos-titulo-listado">
          <div className="col-md-3">CAFFENIO</div>
          <div className="col-md-2">Movimiento</div>
          <div className="col-md-2">Fecha</div>
          <div className="col-md-1">Monto</div>
          <div className="col-md-2">Monedero</div>
          <div className="col-md-2">Prepago</div>
        </div>

        {movimientos &&
          movimientos.map((movimiento) => (
            <Movimiento movimiento={movimiento} />
          ))}
      </div>
      <Pagination
        defaultActivePage={indicePrimerRegistro}
        // ellipsisItem={{
        //   content: <Icon name="ellipsis horizontal" />,
        //   icon: true,
        // }}
        ellipsisItem="..."
        // firstItem={{ content: <Icon name="angle double left" />, icon: true }}
        firstItem="<<"
        // lastItem={{ content: <Icon name="angle double right" />, icon: true }}
        lastItem=">>"
        // prevItem={{ content: <Icon name="angle left" />, icon: true }}
        prevItem="<"
        // nextItem={{ content: <Icon name="angle right" />, icon: true }}
        nextItem=">"
        totalPages={totalPaginas}
        activePage={paginaActual}
        onPageChange={handlePaginationChange}
      />
    </>
  );
};

export default Movimientos;
