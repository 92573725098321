import React, { useState, useEffect } from "react";
const Drive = ({
  drive,
  ChangeView,
  onClick
}) => {

  const [estatus, guardarEstatus] = useState(null);
  if (drive.length === 1) {
    console.log("DRIVE", drive.length);
  }

  useEffect(() => {
    const obtenerDiaActual = () => {
      const diaSemana = new Date();
      const diaActual = diaSemana.getDay();
      const horaActual = diaSemana.getHours();

      if (diaActual === 0) {
        guardarEstatus("cerrado");
        if (
          horaActual >=
          drive.Sunday.substr(0, drive.Sunday.indexOf(":", 0)) &&
          (horaActual % 12 || 12) < drive.Sunday.substr(drive.Sunday.length - 8, 2)
        ) {
          guardarEstatus("abierto");
        }
      } else if (diaActual === 7) {
        guardarEstatus("cerrado");
        if (
          horaActual >= drive.Saturday.substr(0, drive.Saturday.indexOf(":", 0)) &&
          (horaActual % 12 || 12) < drive.Saturday.substr(drive.Saturday.length - 8, 2)
        ) {
          guardarEstatus("abierto");
        }
      } else {
        guardarEstatus("cerrado");
        if (
          horaActual >=
          drive.MondayToFriday.substr(
            0,
            drive.MondayToFriday.indexOf(":", 0)
          ) ||
          (horaActual % 12 || 12) <
          drive.MondayToFriday.substr(drive.MondayToFriday.length - 8, 2)
        ) {
          guardarEstatus("abierto");
        }
      }


      // console.log('Hora ',horaActual);
      // console.log('drive.MondayToFriday.substr(0,drive.MondayToFriday.indexOf(":", 0) ',drive.MondayToFriday.substr(0,drive.MondayToFriday.indexOf(":", 0)))
      // console.log('drive.MondayToFriday.substr(drive.MondayToFriday.length - 8, 2) ',drive.MondayToFriday.substr(drive.MondayToFriday.length - 8, 2))

    };


    const obtenerDiaActualArreglo = () => {
      const diaSemana = new Date();
      const diaActual = diaSemana.getDay();
      const horaActual = diaSemana.getHours();


      if (diaActual === 0) {
        guardarEstatus("cerrado");
        if (
          horaActual >=
          drive.Sunday.substr(0, drive.Sunday.indexOf(":", 0)) &&
          (horaActual % 12 || 12) < drive.Sunday.substr(drive.Sunday.length - 8, 2)
        ) {
          guardarEstatus("abierto");
        }
      } else if (diaActual === 7) {
        guardarEstatus("cerrado");
        if (
          horaActual >= drive.Saturday.substr(0, drive.Saturday.indexOf(":", 0)) &&
          (horaActual % 12 || 12) < drive.Saturday.substr(drive.Saturday.length - 8, 2)
        ) {
          guardarEstatus("abierto");
        }
      } else {
        guardarEstatus("cerrado");
        if (
          horaActual >=
          drive.MondayToFriday.substr(
            0,
            drive.MondayToFriday.indexOf(":", 0)
          ) ||
          (horaActual % 12 || 12) <
          drive.MondayToFriday.substr(drive.MondayToFriday.length - 8, 2)
        ) {
          guardarEstatus("abierto");
        }
      }
    };
    if (drive.length === 1) {
      obtenerDiaActualArreglo();
    } else {
      obtenerDiaActual();
    }

  }, []);

  return drive.length === 1 ?

    (
      <div className="info-drives">
        <p className="titulo" onClick={onClick}>
          {/* {drive.tipo === "1" ? (
          <img
            className="icondrivecaja"
            src="../wp-content/uploads/2021/05/drive_rojo.png"
            alt=""
          />
        ) : drive.tipo === "2" ? (
          <img
            className="icondrivecaja"
            src="../wp-content/uploads/2021/05/drive_amarillo.png"
            alt=""
          />
        ) : (
          <img
            className="icondrivecaja"
            src="../wp-content/uploads/2021/05/drive_gris.png"
            alt=""
          />
        )}{" "} */}
          {drive.DriveName}
        </p>
        {estatus === "abierto" ? (
          <p className="estatus">
            {" "}
            Lunes a Viernes {drive.MondayToFriday} <br /> Sabado {drive.Saturday}{" "}
            <br /> Domingo {drive.Sunday}{" "}
          </p>
        ) : (
          <p className="estatus">CAFFENIO Cerrado temporalmente</p>
        )}
        {/* <p className="estatus">CAFFENIO Cerrado temporalmente</p> */}
        <p className="direccion">{drive.VenueAddress}</p>
        <a href="#!" onClick={() => ChangeView([drive.Latitude, drive.Longitude])}>
          <p className="liga">Ver en Maps</p>
        </a>
      </div>
    ) :


    (
      <div className="info-drives">
        <p className="titulo" onClick={onClick}>
          {/* {drive.tipo === "1" ? (
          <img
            className="icondrivecaja"
            src="../wp-content/uploads/2021/05/drive_rojo.png"
            alt=""
          />
        ) : drive.tipo === "2" ? (
          <img
            className="icondrivecaja"
            src="../wp-content/uploads/2021/05/drive_amarillo.png"
            alt=""
          />
        ) : (
          <img
            className="icondrivecaja"
            src="../wp-content/uploads/2021/05/drive_gris.png"
            alt=""
          />
        )}{" "} */}
          {drive.DriveName}
        </p>
        {estatus === "abierto" ? (
          <p className="estatus">
            {" "}
            Lunes a Viernes {drive.MondayToFriday} <br /> Sabado {drive.Saturday}{" "}
            <br /> Domingo {drive.Sunday}{" "}
          </p>
        ) : (
          <p className="estatus">CAFFENIO Cerrado temporalmente</p>
        )}
        {/* <p className="estatus">CAFFENIO Cerrado temporalmente</p> */}
        <p className="direccion">{drive.VenueAddress}</p>
        <a href="#!" onClick={() => ChangeView([drive.Latitude, drive.Longitude])}>
          <p className="liga">Ver en Maps</p>
        </a>
      </div>
    );
};

export default Drive;