import React, { useState, useEffect } from "react";
import { Encrypt, Decrypt } from "../Utils/EncryptDecrypt";
import clienteAxios from "../config/axios";
import "./Miperfil.css";
import ModalVerDirecciones from "../Modal/ModalAgregarDireccion";
import ModalAgregarDireccion from "../Modal/ModalAgregarDireccion";
import VerAgregarDireccion from "./Direccion/VerAgregarDireccion";
import MiPerfilFan from "./MiPerfilFan";
import Error from "../Error";

const Miperfil = () => {
  const [error, guardarError] = useState("");
  const [usuario, guardarUsuario] = useState([]);
  const [edad, guardarEdad] = useState(0);
  const [mostrar, guardarMostrar] = useState(false);
  const [informacioncliente, guardarInformacioncliente] = useState([]);
  const [MembresiaVigente, guardarMembresiaVigente] = useState([]);
  const [actualizar, guardarActualizar] = useState(0);

  useEffect(() => {
    guardarActualizar(false);
    if (Decrypt("token") == null) {
      window.location.href = "inicio/";
    }
    guardarUsuario(Decrypt("usuario"));
    const obtenerInformacionCliente = async () => {
      await clienteAxios("/api/cliente/get_cliente/", {
        headers: {
          Authorization: "Bearer " + Decrypt("token"),
        },
      })
        .then((respuesta) => {
          Encrypt("informacioncliente", respuesta.data);
          guardarInformacioncliente(respuesta.data);
          guardarEdad(calcularEdad(respuesta.data.FechaNacimiento));
          guardarMembresiaVigente(respuesta.data.MembresiaVigente);
          console.log(respuesta.data);
        })
        .catch((error) => {
          console.log(
            "No puede iniciar sesión con las credenciales proporcionadas."
          );
          guardarError(error);
        });
    };
    obtenerInformacionCliente();
  }, [actualizar]);

  const calcularEdad = () => {
    const fechaNace = new Date(FechaNacimiento);
    const fechaActual = new Date();

    const mes = fechaActual.getMonth();
    const dia = fechaActual.getDate();
    const año = fechaActual.getFullYear();

    fechaActual.setDate(dia);
    fechaActual.setMonth(mes);
    fechaActual.setFullYear(año);

    const edad = Math.floor(
      (fechaActual - fechaNace) / (1000 * 60 * 60 * 24) / 365
    );

    return edad;
  };

  const showModal = () => {
    guardarMostrar(true);
  };

  const {
    id,
    idCuentaLealtad,
    Nombre,
    ApellidoP,
    Email,
    Telefono,
    Activo,
    Saldo,
    idTipoIntelicard,
    SaldoMonedero,
    Nivel,
  } = usuario;
  const { Colonia, Direcciones, FechaNacimiento, Genero, Ocupacion } =
    Decrypt("informacioncliente");

  const editarPerfil = () => {
    window.location.href = "mi-perfil-edicion/";
  };

  return (
    <div>
      {error ? <Error mensaje={error} /> : null}
      {mostrar && (
        <ModalAgregarDireccion
          guardarMostrar={guardarMostrar}
          guardarActualizar={guardarActualizar}
        />
      )}
      {/* {mostrar && <ModalVerDirecciones guardarMostrar={guardarMostrar} />} */}
      <div className="bienvenida-info">
        <div className="miperfil-foto">
          <img src="../wp-content/uploads/2021/10/grupo-392.png" alt="" />
        </div>
        <div className="contenedor-txt">
          <div className="txt-bienvenida">Bienvenida, {Nombre}</div>
          <div className="txt-cuenta">CTA {idCuentaLealtad}</div>
        </div>
      </div>
      <hr className="miperfil-divisor" />
      {MembresiaVigente.length > 0 && (
        <MiPerfilFan MembresiaVigente={MembresiaVigente} />
      )}
      <hr className="miperfil-divisor" />
      <div className="miperfil-contenedor">
        <div className="miperfil-renglon">
          <div className="miperfil-columnas pb5 pd5">
            <label className="miperfil-etiqueta-titulo">Datos Personales</label>
          </div>
          <div className="miperfil-columnas pb5 pi5">
            {/* <div className="btn-editar">
              <i class="fa fa-edit">
                {" "}
                <a href="#!" onClick={editarPerfil}>
                  Editar perfil
                </a>
              </i>
            </div> */}
          </div>
        </div>
        <div className="miperfil-renglon">
          <div className="miperfil-columnas pb5 pd5">
            <label className="miperfil-etiqueta-titulo">Edad</label>
            <div className="miperfil-etiqueta-descripcion">{edad}</div>
          </div>
          <div className="miperfil-columnas pb5 pi5">
            <label className="miperfil-etiqueta-titulo">Correo</label>
            <div className="miperfil-etiqueta-descripcion">{Email}</div>
          </div>
        </div>
        <div className="miperfil-renglon">
          <div className="miperfil-columnas pb5 pd5">
            <label className="miperfil-etiqueta-titulo">Ocupación</label>
            <div className="miperfil-etiqueta-descripcion">{Ocupacion}</div>
          </div>
          <div className="miperfil-columnas pb5 pi5">
            <label className="miperfil-etiqueta-titulo">Celular</label>
            <div className="miperfil-etiqueta-descripcion">{Telefono}</div>
          </div>
        </div>
      </div>
      <hr className="miperfil-divisor" />
      <div className="miperfil-contenedor">
        <div className="miperfil-renglon">
          <VerAgregarDireccion Direcciones={Direcciones} Ver="false" />
        </div>
        <div className="marginLeft">
          <div>
            <a
              href="#"
              onClick={showModal}
              className="miperfil-btn-agregar-direccion"
            >
              Agregar dirección
            </a>
          </div>
        </div>
      </div>
      <hr className="miperfil-divisor" />
      {/* <div className="miperfil-contenedor">
        <label htmlFor="">MI CAFFENIO</label>
        <div className="miperfil-renglon">
          <div className="miperfil-columnas pd5">
            <img
              src="/wp-content/uploads/sites/2/2021/08/grupo-842.png"
              alt=""
              className="producto-favorito"
            />
          </div>
          <div className="miperfil-columnas pt3">
            <img
              src="/wp-content/uploads/sites/2/2021/08/grupo-841.png"
              alt=""
              className="caffenio-favorito"
            />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Miperfil;
