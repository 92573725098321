import React, { useState } from "react";
import Drives from "./Drives";
import ListadoMunicipio from "./ListadoMunicipio";
const Municipios = ({
  municipios: { nombre, estado, drives },
  municipios,
  guardarCoordenadas,
  colocarPosicion,
}) => {
  const [visible, guardarVisible] = useState(false);
  let c = 0;
  const onClick = () => {
    if (visible) {
      guardarVisible(false);
    } else {
      guardarVisible(true);
    }
  };

  return (
    <div>
      {municipios.map((municipio, key) => (
        <ListadoMunicipio
          nombreMunicipio={municipio.nombre}
          nombreEstado={municipio.estadoNombre}
          id={municipio.ClassificationId}
          drives={municipio.drives}
          guardarCoordenadas={guardarCoordenadas}
          colocarPosicion={colocarPosicion}
        />
      ))}

      {/* <div className="col-md-2">
            {visible ? (
              <img
                src="../wp-content/uploads/2021/05/abierto.png"
                className="imgAbierto"
              />
            ) : (
              <img
                src="../wp-content/uploads/2021/05/cerrado.png"
                className="imgCerrado"
              />
            )}
          </div> */}
    </div>
  );
};

export default Municipios;
