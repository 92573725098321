import React, { useState, useEffect } from "react";
import { Encrypt, Decrypt } from "../Utils/EncryptDecrypt";
import clienteAxios from "../config/axios";
import ModalAgregarDireccion from "../Modal/ModalAgregarDireccion";
import VerAgregarDireccion from "../Perfil/Direccion/VerAgregarDireccion";
import Error from "../Error";

const Editarperfil = () => {
  const [error, guardarError] = useState("");
  const [usuario, guardarUsuario] = useState([]);
  const [informacioncliente, guardarInformacioncliente] = useState([]);
  const [edad, guardarEdad] = useState(0);
  const [mostrar, guardarMostrar] = useState(false);
  const [actualizar, guardarActualizar] = useState(0);

  useEffect(() => {
    guardarActualizar(false);
    if (Decrypt("token") == null) {
      window.location.href = "inicio/";
    }
    guardarUsuario(Decrypt("usuario"));
    const obtenerInformacionCliente = async () => {
      await clienteAxios("/api/cliente/get_cliente/", {
        headers: {
          Authorization: "Bearer " + Decrypt("token"),
        },
      })
        .then((respuesta) => {
          Encrypt("informacioncliente", respuesta.data);
          guardarEdad(calcularEdad(respuesta.data.FechaNacimiento));
          guardarInformacioncliente(respuesta.data);
          console.log(respuesta.data);
        })
        .catch((error) => {
          console.log(
            "No puede iniciar sesión con las credenciales proporcionadas."
          );
          guardarError(error);
        });
    };
    obtenerInformacionCliente();
  }, [actualizar]);

  const calcularEdad = () => {
    const fechaNace = new Date(FechaNacimiento);
    const fechaActual = new Date();

    const mes = fechaActual.getMonth();
    const dia = fechaActual.getDate();
    const año = fechaActual.getFullYear();

    fechaActual.setDate(dia);
    fechaActual.setMonth(mes);
    fechaActual.setFullYear(año);

    const edad = Math.floor(
      (fechaActual - fechaNace) / (1000 * 60 * 60 * 24) / 365
    );

    return edad;
  };

  const showModal = () => {
    guardarMostrar(true);
  };

  const onChange = (e) => {
    guardarInformacioncliente({
      ...informacioncliente,
      [e.target.name]: e.target.value,
    });
  };

  const {
    id,
    idCuentaLealtad,
    Nombre,
    ApellidoP,
    // Email,
    // Telefono,
    Activo,
    Saldo,
    idTipoIntelicard,
    SaldoMonedero,
    Nivel,
  } = usuario;

  const {
    Email,
    Telefono,
    Colonia,
    FechaNacimiento,
    Genero,
    MembresiaVigente,
    Ocupacion,
  } = informacioncliente;

  const { Direcciones } = Decrypt("informacioncliente");

  const onSubmit = async (e) => {
    e.preventDefault();
    let data;
    let headers = {
      headers: {
        Authorization: "Bearer " + Decrypt("token"),
      },
    };
    data = {
      Ocupacion: Ocupacion,
      Telefono: Telefono,
      Email: Email,
    };
    await clienteAxios
      .put("/api/cliente/update_cliente/", data, headers)
      .then((respuesta) => {
        console.log(respuesta);
        // window.location.href = "micaffenioinicioconcuenta/";
      })
      .catch((error) => {
        console.log(
          "No puede iniciar sesión con las credenciales proporcionadas."
        );
        console.log(error.message);
        guardarError(error);
      });
  };

  return (
    <div>
      {error ? <Error mensaje={error} /> : null}
      {mostrar && (
        <ModalAgregarDireccion
          guardarMostrar={guardarMostrar}
          guardarActualizar={guardarActualizar}
        />
      )}
      <div className="bienvenida-info">
        <div className="miperfil-foto">
          <img src="/wp-content/uploads/sites/2/2021/08/grupo-840.png" alt="" />
        </div>
        <div className="contenedor-txt">
          <div className="txt-bienvenida">Bienvenida, {Nombre}</div>
          <div className="txt-cuenta">CTA {idCuentaLealtad}</div>
        </div>
      </div>
      <hr className="miperfil-divisor" />
      <div className="miperfil-contenedor">
        <form action="" onSubmit={onSubmit}>
          <div className="editarperfil-titulo">Datos personales</div>
          <div className="miperfil-renglon">
            <div className="miperfil-columnas pb5 pd5"></div>
            <div className="miperfil-columnas pb5 pi5">
              <label htmlFor="" className="miperfil-etiqueta-titulo">
                Correo
              </label>
              <input
                type="email"
                className="form-control"
                placeholder="Correo"
                id="Email"
                name="Email"
                value={Email}
                onChange={onChange}
              />
              <label className="">
                Se enviará un código de confirmación a tu correo.
              </label>
            </div>
          </div>
          <div className="miperfil-renglon">
            <div className="miperfil-columnas pb3 pd5">
              <label htmlFor="" className="miperfil-etiqueta-titulo">
                Ocupación
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Ocupación"
                id="Ocupacion"
                name="Ocupacion"
                value={Ocupacion}
                onChange={onChange}
              />
            </div>
            <div className="miperfil-columnas pb3 pi5">
              <label htmlFor="" className="miperfil-etiqueta-titulo">
                Celular
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Celular"
                id="Telefono"
                name="Telefono"
                value={Telefono}
                onChange={onChange}
              />
              <label className>
                Se enviará un código de confirmación a tu celular.
              </label>
            </div>
          </div>
        </form>
      </div>
      <hr className="divisor" />

      <div className="miperfil-contenedor">
        <div className="miperfil-renglon">
          <VerAgregarDireccion Direcciones={Direcciones} Ver="false" />
        </div>
        <div className="marginLeft">
          <div>
            <a
              href="#"
              onClick={showModal}
              className="miperfil-btn-agregar-direccion"
            >
              Agregar dirección
            </a>
          </div>
        </div>
      </div>
      <div>
        <input type="submit" value="Guardar" className="miperfil-btn-guardar" />
      </div>
      {/* <hr className="miperfil-divisor" />
      <div className="miperfil-contenedor">
        <div className="miperfil-renglon">
          <div className="miperfil-columnas pd5 pt3">
            <img
              src="../wp-content/uploads/2021/02/grupo-842.png"
              alt=""
              className=""
            />
          </div>
          <div className="miperfil-columnas">
            <img
              src="../wp-content/uploads/2021/02/grupo-841.png"
              alt=""
              className=""
            />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Editarperfil;
