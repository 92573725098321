import React, { useState, useEffect } from "react";
import { clienteAxiosMobile } from "../Utils/axios.js";
import "./Productos.css";
import MenuProductos from "./MenuProductos";
const Productos = () => {
  const [clasificaciones, guardarClasificaciones] = useState([]);
  const [menus, guardarMenus] = useState([]);
  const [sabores, guardarSabores] = useState([]);
  const [presentaciones, guardarPresentaciones] = useState([]);

  useEffect(() => {
    const obtenerMenu = async () => {
      await clienteAxiosMobile("v2/menus")
        .then((respuesta) => {
          guardarMenus(respuesta.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    obtenerMenu();
    const obtenerClasificaciones = async () => {
      await clienteAxiosMobile(
        "v2/classifications?version=3"
      )
        .then((respuesta) => {
          guardarClasificaciones(respuesta.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    obtenerClasificaciones();

    const obtenerSabores = async () => {
      await clienteAxiosMobile("v1/flavors")
        .then((respuesta) => {
          guardarSabores(respuesta.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    obtenerSabores();

    const obtenerPresentaciones = async () => {
      await clienteAxiosMobile("v1/presentations")
        .then((respuesta) => {
          guardarPresentaciones(respuesta.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    obtenerPresentaciones();
  }, []);
  return (
    <>
      <div className="menu-productos">
        {/* <div> */}
        {menus.length > 0 &&
          clasificaciones.length > 0 &&
          sabores.length > 0 ? (
          <MenuProductos
            menus={menus}
            clasificaciones={clasificaciones}
            sabores={sabores}
            presentaciones={presentaciones}
          />
        ) : null}
        {/* </div> */}
      </div>
    </>
  );
};

export default Productos;
