import axios from 'axios';

export const clienteAxios = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

export const clientewpAxios = axios.create({
  baseURL: process.env.REACT_APP_URL_MICAFFENIO,
});

export const clientewpCaffenioAxios = axios.create({
  baseURL: process.env.REACT_APP_URL_CAFFENIO,
});

export const clienteAxiosEspressa = axios.create({
  baseURL: process.env.REACT_APP_URL_ESPRESSA,
});

export const clienteAxiosMobile = axios.create({
  baseURL: process.env.REACT_APP_URL_MOBILE,
});
