import React, { useEffect } from "react";
import { Button } from "@material-ui/core";
// import clienteAxios from "../config/axios";
import { Encrypt, Decrypt } from "../../Utils/EncryptDecrypt";
import "./MembresiaDireccion.css";
const MembresiaDireccion = () => {
  //   const [direccionseleccionada, guardarDireccionseleccionada] = useState([]);
  const { Direcciones } = Decrypt("informacioncliente");

  useEffect(() => {
    if (Decrypt("token") == null) {
      window.location.href = "inicio/";
    }
  }, []);

  const agregardireccion = () => {
    window.location.href = "agregar-direccion/";
  };
  const confirmardireccion = () => {
    if (Direcciones.length > 0) {
      window.location.href = "plan/";
    }
  };

  return (
    <>
      <div className="membresia-direccion-contenedor">
        <div className="membresia-direccion-descripcion">
          {Direcciones.length > 0
            ? Direcciones.filter(
                (direccion) =>
                  direccion.principal === true && direccion.activo === true
              ).map((direccionPrincipal) => (
                <div className="">
                  {direccionPrincipal.calle} #{direccionPrincipal.numero} entre{" "}
                  {direccionPrincipal.calle_opcional} <br />
                  Colonia {direccionPrincipal.colonia}{" "}
                  {direccionPrincipal.municipio}, {direccionPrincipal.estado}
                  <br />
                  {" CP. "} {direccionPrincipal.codigo_postal}
                </div>
              ))
            : null}
        </div>
        <div className="fan-alineacion-derecha">
          <Button
            variant="outlined"
            onClick={agregardireccion}
            className="fan-btn-noreactivar"
          >
            Agregar dirección
          </Button>
          <Button
            variant="outlined"
            onClick={confirmardireccion}
            className="fan-btn-reactivar"
          >
            Confirmar dirección
          </Button>
        </div>
      </div>
    </>
  );
};

export default MembresiaDireccion;
