import React from "react";
import ListadoMunicipio from "./ListadoMunicipio";
const Menu = ({
  guardarCoordenadas,
  ubicaciones,
  ChangeView
}) => {

  return (
    <>
      <div>
      {ubicaciones.map((drive, key) => (
        <ListadoMunicipio
          nombreLugar={drive.nombre}
          drives={drive.drives}
          guardarCoordenadas={guardarCoordenadas}
          ChangeView={ChangeView}
        />
      ))}
      </div>
    </>
  );
};

export default Menu;
