import React, { useState, useEffect } from "react";
import ColocarPosicion from "./components/ColocarPosicion";
import clientewpCaffenioAxios from "../config/axios";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
} from "react-leaflet";
import { MarkerIcon } from "./react-leaflet-icon.js";
import "./App.css";
import Municipios from "./components/Municipios";
import Drive from "./components/Drive";
import MenuDrives from "./components/MenuDrives";
import data from "../Data/db.json";
import clienteAxios from "../config/axios";


const ACCESS_TOKEN =
"pk.eyJ1IjoiZnJvaWxhbnJhbWlyZXoiLCJhIjoiY2ttbDB1cHVuMTZreTJvdDRydWJoY2J5NiJ9.tSBy5ODBA3-q7prlJ2DGUw";

const URL = `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${ACCESS_TOKEN}`;
const ATTRIBUTION =
'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>';

const coordenadas_fijas = [29.0609, -111];

function App() {
  const [coordenadas, guardarCoordenadas] = useState([]);
  const [zoom, guardarZoom] = useState(16);
  const [municipios, guardarMunicipios] = useState([]);
  const [municipiosrespaldo, guardarMunicipiosrespaldo] = useState([]);
  const [drives, guardarDrives] = useState([]);
  const [bdrives, guardarBdrives] = useState([]);
  const [busqueda, guardarBusqueda] = useState(null);
  const [ubicaciones, guardarUbicaciones] = useState([]);
  
  useEffect(() => {

    const obtenerUbicaciones = async () => {
      await clienteAxios("https://mobileservice.caffenio.com/nspv/v2/venues")
        .then((respuesta) => {
          guardarUbicaciones(respuesta.data);
          console.log('Ubicaciones Mapa', respuesta.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    obtenerUbicaciones();

    /*************************Con APIS******************************************/
    // const headers = {
    //   headers: {
    //     "X-Api-Key": "fXpwOGyg.vvPBIAbLwEzuSsBEzLpXEGqVmciX6urh",
    //   },
    // };
    /*************************Con APIS******************************************/
    const posicionActual = () => {
      guardarCoordenadas(coordenadas_fijas);
      navigator.geolocation.getCurrentPosition(function (position) {
        const latitude = position.coords.latitude;
        const lng = position.coords.longitude;
        let coordenadas = [latitude, lng];
        guardarCoordenadas(coordenadas);
      });
    };
    posicionActual();


    /*******************************************Con db.json*********************************************************/
    const cargarpuntos = async()=>{
      guardarDrives(data.drives);
    }
    cargarpuntos();

    const obtenerMunicipios = async()=>{
      guardarMunicipios(data.municipios);
      guardarMunicipiosrespaldo(data.municipios);
    }
    obtenerMunicipios();


    /*******************************************Con db.json*********************************************************/

    /***************************************Con APIS ***************************************************************/
    // const cargarpuntos = async () => {
    //   const respuesta = await clientewpCaffenioAxios.get(
    //     "/api/drive/get_ubicaciones/",
    //     headers
    //   );
    //   let drives = respuesta.data;
    //   let i;
    //   for (i = 0; i < drives.length; i++) {
    //     drives[i].id = i + 1;
    //     drives[i].coordenadas = [drives[i].latitude, drives[i].longitude];
    //   }
    //   guardarDrives(respuesta.data);
    // };
    // cargarpuntos();

    // const obtenerMunicipios = async () => {
    //   let totalMunicipios = [];

    //   let estadoslocal = [
    //     {
    //       nombre: "Sonora",
    //     },
    //     {
    //       nombre: "Sinaloa",
    //     },
    //     {
    //       nombre: "Baja California",
    //     },
    //     {
    //       nombre: "Chihuahua",
    //     },
    //     {
    //       nombre: "Durango",
    //     },
    //   ];
    //   for (let el = 0; el < estadoslocal.length; el++) {
    //     await clientewpCaffenioAxios
    //       .get(
    //         `/api/direccion/get_municipios/?estado=${estadoslocal[el].nombre}`,
    //         headers
    //       )
    //       .then((respuestamunicipios) => {
    //         for (let m = 0; m < respuestamunicipios.data.length; m++) {
    //           respuestamunicipios.data[m].estado = estadoslocal[el].nombre;
    //           totalMunicipios = totalMunicipios.concat(
    //             respuestamunicipios.data[m]
    //           );
    //         }

    //         // totalMunicipios.estado = nombre;
    //         // totalMunicipios.push(respuestamunicipios.data);
    //         // console.log(totalMunicipios);

    //         // for (let dd = 0; dd < drivesDistinct.length; dd++) {
    //         //   totalMunicipiosReal = totalMunicipios.filter(
    //         //     (municipio) => municipio.nombre == drivesDistinct[dd]
    //         //   );
    //         //   totalMunicipiosRealAbs =
    //         //     totalMunicipiosRealAbs.concat(totalMunicipiosReal);
    //         // }

    //         // const key = "nombre";
    //         // const municipioDistinct = [
    //         //   ...new Map(
    //         //     totalMunicipiosRealAbs.map((item) => [item[key], item])
    //         //   ).values(),
    //         // ];
    //         // guardarMunicipios(municipioDistinct);
    //         // guardarMunicipiosrespaldo(municipioDistinct);
    //         guardarMunicipios(totalMunicipios);
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //   }
    // };
    // obtenerMunicipios();

    /*******************************Con APIS***************************************/
  }, []);

  console.log('drives ',ubicaciones);

  const obtenerBusqueda = (e) => {
    e.preventDefault();
    guardarBusqueda(([e.target.name] = e.target.value));
  };

  const buscar = () => {
    let resultadoBusqueda = [];
    let resultadoBusquedaDrive = [];
    guardarBdrives([]);
    resultadoBusqueda = municipios.filter((municipio) => municipio.estadoNombre === busqueda);
    if (resultadoBusqueda.length > 0) {
      guardarMunicipios(resultadoBusqueda);
      console.log("BusquedaEstados");
    } else {
      resultadoBusqueda = municipios.filter((municipio) => municipio.nombre === busqueda);
      if (resultadoBusqueda.length > 0) {
        guardarMunicipios(resultadoBusqueda);
        console.log("BusquedaMunicipios");
      } else {
        resultadoBusquedaDrive = drives.filter((drive) => drive.nombre_drive === busqueda);
        if (resultadoBusquedaDrive.length > 0) {
          console.log("BusquedaDrives");
          guardarBdrives(resultadoBusquedaDrive);
        } else {
          guardarMunicipios(municipiosrespaldo);
          console.log("TodosMunicipios");
        }
      }
    }
  };

  const colocarPosicion = (puntos) => {
    this.map.flyTo(puntos, 18);
  };

  console.log("Busqueda",busqueda);
  console.log("Municipios",municipios);
  console.log("Drives",bdrives);

  return (
    <div>
      {coordenadas.length > 0 ? (
        <MapContainer center={coordenadas} zoom={zoom}>
          <TileLayer url={URL} attribution={ATTRIBUTION} />
          <ColocarPosicion
            coordenadas={coordenadas}
            guardarCoordenadas={guardarCoordenadas}
          />
          {drives.map((drive, index) => (
            <Marker key={index} position={drive.coordenadas} icon={MarkerIcon}>
              <Popup>
                <b>{drive.nombre_drive}</b>
                <br />
                CAFFENIO
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      ) : (
        <div>
          <h1>ERROR</h1>
          {coordenadas.length}
        </div>
      )}

      <div className="caja-buscar">
        <input
          name="buscar"
          placeholder="Buscar ubicación"
          onChange={obtenerBusqueda}
        />
        <img
          src="../wp-content/uploads/2021/05/ubicacion.png"
          alt=""
          className="btnBuscar"
          onClick={buscar}
        />
      </div>

      <div className="menu-ciudades">
        <label>Ubicaciones</label>
        <div>
        {drives.length > 0 && municipios.length > 0 && (
            <MenuDrives
              drives={drives}
              municipios={municipios}
              guardarCoordenadas={guardarCoordenadas}
              colocarPosicion={colocarPosicion}
            />
          )}
          {/* {drives.length > 0 && municipios.length >= 200 && (
            <MenuDrives
              drives={drives}
              municipios={municipios}
              guardarCoordenadas={guardarCoordenadas}
              colocarPosicion={colocarPosicion}
            />
          )} */}
          {/* {bdrives.length === 0 ? (
            municipios.map((municipio, key) => (
              <Municipios
                nombre={municipio.nombre}
                estado={municipio.estado}
                key={municipio.id}
                drives={
                  municipio.nombre === "Hermosillo"
                    ? drivesHermosillo
                    : municipio.nombre === "Chihuahua"
                    ? drivesChihuahua
                    : municipio.nombre === "Durango"
                    ? drivesDurango
                    : municipio.nombre === "Nogales"
                    ? drivesNogales
                    : municipio.nombre === "Guaymas"
                    ? drivesGuaymas
                    : municipio.nombre === "Navojoa"
                    ? drivesNavojoa
                    : municipio.nombre === "Obregon"
                    ? drivesObregon
                    : municipio.nombre === "Culiacan"
                    ? drivesCuliacan
                    : municipio.nombre === "Rosarito"
                    ? drivesRosarito
                    : municipio.nombre === "Ensenada"
                    ? drivesEnsenada
                    : municipio.nombre === "Tijuana"
                    ? drivesTijuana
                    : municipio.nombre === "Mexicali"
                    ? drivesMexicali
                    : municipio.nombre === "Mochis"
                    ? drivesMochis
                    : drivesSLRC
                }
                guardarCoordenadas={guardarCoordenadas}
                colocarPosicion={colocarPosicion}
              />
            ))
          ) : (
            <Drive
              drive={bdrives}
              guardarBdrives={guardarBdrives}
              guardarCoordenadas={guardarCoordenadas}
              colocarPosicion={colocarPosicion}
            />
          )} */}
        </div>
      </div>
    </div>
  );
}

export default App;
