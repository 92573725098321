import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Registro from './Auth/Registro';
import Inicio from './Inicio/Inicio';
import Miperfil from './Perfil/Miperfil';
import Editarperfil from './Perfil/Editarperfil';
import reportWebVitals from './reportWebVitals';
import Ubicacion from './Ubicacion/App';
import MembresiaMensual from './Membresia/MembresiaMensual';
import MembresiaSemestral from './Membresia/MembresiaSemestral';
import MembresiaAnual from './Membresia/MembresiaAnual';
import SeleccionarPlan from './Membresia/Plan/SeleccionarPlan';
import CapturaTarjeta from './Membresia/Tarjeta/CapturaTarjeta';
import TerminosCondiciones from './Membresia/Tarjeta/TerminosCondiciones';
import Confirmacion from './Membresia/Confirmacion/Confirmacion';
import Final from './Membresia/Finalizacion/Final';
import Productos from './Productos/Productos';
import Movimientos from './Movimientos/Movimientos';
import MembresiaDireccion from './Membresia/Direccion/MembresiaDireccion';
import AgregarDireccion from './Membresia/Direccion/AgregarDireccion';
import ReanudarMembresia from './Membresia/Reanudar/ReanudarMembresia';
import ReanudarMembresiaError from './Membresia/Reanudar/ReanudarMembresiaError';
import NoReanudarMembresia from './Membresia/Reanudar/NoReanudarMembresia';
import CancelarMembresia from './Membresia/Cancelar/CancelarMembresia';
import InicioMembresia from './Inicio/InicioMembresia';
import Mapa from './Mapa/App';
import Murales from './espressa/murales';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementsByClassName("app")
// );

let target = document.getElementById('pr-root');
if (target) {
  ReactDOM.render(<App />, target);
}

target = document.getElementById('mapa-root');
if (target) {
  ReactDOM.render(<Mapa />, target);
}

target = document.getElementById('registro-root');
if (target) {
  ReactDOM.render(<Registro />, target);
}

target = document.getElementById('inicio-root');
if (target) {
  ReactDOM.render(<Inicio />, target);
}

target = document.getElementById('miperfil-root');
if (target) {
  ReactDOM.render(<Miperfil />, target);
}

target = document.getElementById('editarperfil-root');
if (target) {
  ReactDOM.render(<Editarperfil />, target);
}

target = document.getElementById('ubicacion-root');
if (target) {
  ReactDOM.render(<Ubicacion />, target);
}

target = document.getElementById('membresiaMensual-root');
if (target) {
  ReactDOM.render(<MembresiaMensual />, target);
}

target = document.getElementById('membresiaSemestral-root');
if (target) {
  ReactDOM.render(<MembresiaSemestral />, target);
}
target = document.getElementById('membresiaAnual-root');
if (target) {
  ReactDOM.render(<MembresiaAnual />, target);
}
target = document.getElementById('seleccionarPlan-root');
if (target) {
  ReactDOM.render(<SeleccionarPlan />, target);
}
target = document.getElementById('capturaTarjeta-root');
if (target) {
  ReactDOM.render(<CapturaTarjeta />, target);
}
target = document.getElementById('checkbox-root');
if (target) {
  ReactDOM.render(<TerminosCondiciones />, target);
}
target = document.getElementById('confirmacion-root');
if (target) {
  ReactDOM.render(<Confirmacion />, target);
}
target = document.getElementById('final-root');
if (target) {
  ReactDOM.render(<Final />, target);
}
target = document.getElementById('productos-root');
if (target) {
  ReactDOM.render(<Productos />, target);
}
target = document.getElementById('movimientos-root');
if (target) {
  ReactDOM.render(<Movimientos />, target);
}
target = document.getElementById('membresiadireccion-root');
if (target) {
  ReactDOM.render(<MembresiaDireccion />, target);
}
target = document.getElementById('agregardireccion-root');
if (target) {
  ReactDOM.render(<AgregarDireccion />, target);
}
target = document.getElementById('reanudarmembresia-root');
if (target) {
  ReactDOM.render(<ReanudarMembresia />, target);
}
target = document.getElementById('reanudarmembresiaerror-root');
if (target) {
  ReactDOM.render(<ReanudarMembresiaError />, target);
}
target = document.getElementById('noreanudarmembresia-root');
if (target) {
  ReactDOM.render(<NoReanudarMembresia />, target);
}
target = document.getElementById('cancelarmembresia-root');
if (target) {
  ReactDOM.render(<CancelarMembresia />, target);
}
target = document.getElementById('iniciomembresia-root');
if (target) {
  ReactDOM.render(<InicioMembresia />, target);
}

target = document.getElementById('murales-root');
if (target) {
  ReactDOM.render(<Murales />, target);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
