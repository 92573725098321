import React from "react";
const Promocion = ({ promocion }) => {
  return (
    <>
      <div>{promocion.beneficio}</div>
    </>
  );
};

export default Promocion;
