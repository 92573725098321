import React, { useState, useEffect } from "react";
import { Encrypt, Decrypt } from "../Utils/EncryptDecrypt";
import clienteAxios from "../config/axios";
import Promocion from "./Promocion";
import "./Membresia.css";

const MembresiaAnual = () => {
  const [usuario, guardarUsuario] = useState([]);
  const [membresia, guardarMembresia] = useState(null);

  useEffect(() => {
    if (Decrypt("token") == null) {
      // window.location.href = "micaffeniologin/";
      window.location.href = "inicio/";
    }

    guardarUsuario(Decrypt("usuario"));
    guardarMembresia(Decrypt("MembresiaAnual"));
  }, []);

  const { id, nombre, short_name, descripcion, costo, activo, promociones } =
    Decrypt("membresiaAnual");

  return (
    <div>
      <div className="membresia-candado">
        <img src="/wp-content/uploads/sites/2/2021/08/grupo-815.png" alt="" />
      </div>
      <div className="membresia-titulo membresia-centrar membresia-titulo-blanco">
        <label htmlFor="">{short_name}</label>
      </div>
      <div className="membresia-costo membresia-centrar membresia-titulo-blanco">
        <label htmlFor="">${costo}MXN</label>
      </div>
      <div className="membresia-contenedor-boton membresia-centrar">
        <a href="confirmar-direccion/" className="membresia-boton boton-rojo">
          Adquirir
        </a>
      </div>
      <div className="membresia-contenedor-promociones">
        {promociones.map((promocion, key) => (
          <Promocion promocion={promocion} />
        ))}
      </div>
    </div>
  );
};

export default MembresiaAnual;
