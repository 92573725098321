import React, { useEffect, useState } from "react";
import Municipios from "./Municipios";
const MenuDrives1 = ({
  drives,
  municipios,
  guardarCoordenadas,
  colocarPosicion,
}) => {
  const [drivesfinal, guardarDrivesFinal] = useState([]);

  useEffect(() => {
    const concatenarArreglos = () => {
      let res = [];
      let resDrives = [];
      for (let m = 0; m < municipios.length; m++) {
        res = drives.filter((drive) => drive.municipio == municipios[m].id);

        if (res.length > 0) {
          municipios[m].drives = res;
          resDrives.push(municipios[m]);
        }
      }
      guardarDrivesFinal(resDrives);
    };
    concatenarArreglos();
  }, []);

  return (
    <>
      <div>
        {municipios.length > 0 && (
          <Municipios
            municipios={drivesfinal}
            guardarCoordenadas={guardarCoordenadas}
            colocarPosicion={colocarPosicion}
          />
        )}
      </div>
    </>
  );
};

export default MenuDrives1;
