import React, { useState } from "react";
import Drive from "./Drive";
const Drives = ({ nombre, drive, guardarCoordenadas, ChangeView }) => {
  const [visible, guardarVisible] = useState(false);

  const onClick = () => {
    if (visible) {
      guardarVisible(false);
    } else {
      guardarVisible(true);
    }
  };

  
  return (
    <div>
      <div>
        <div className="padre">
          {/* {
            tipo === "1" ? (
              <img
                className="icondrives"
                src="../wp-content/uploads/2021/05/drive_rojo.png"
                alt=""
              />
            ) : (
              <img
                className="icondrives"
                src="../wp-content/uploads/2021/05/drive_amarillo.png"
                alt=""
              />
            )
          } */}

          <label className={!visible ? "visible drives" : "no-visible drives"} onClick={onClick}>
            {nombre}
          </label>
          <div className={visible ? "visible caja-info-drives" : "no-visible"}>
            {
              <Drive
              drive={drive}
              // guardarCoordenadas={guardarCoordenadas}
              ChangeView={ChangeView}
              onClick={onClick}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drives;
