import React, { useState } from "react";
import clienteAxios from "../../config/axios";
import { Encrypt, Decrypt } from "../../Utils/EncryptDecrypt";
import { Button } from "@material-ui/core";
import "./CancelarMembresia.css";
// import Error from "../Error";
const CancelarMembresia = () => {
  //   const [error, guardarError] = useState("");
  //   const [noreactivar, guardarNoreactivar] = useState(false);
  const { MembresiaVigente } = Decrypt("informacioncliente");
  const onClick = async (key) => {
    let data = {};
    const url = "/api/membresia/" + key + "/cancelar";
    await clienteAxios
      .delete(url, {
        headers: {
          Authorization: "Bearer " + Decrypt("token"),
        },
      })
      .then((respuesta) => {
        // guardarActualizar(true);
        Encrypt("actualizar", true);
        obtenerMembresia();
        window.location.href = "membresia-cancelada/";
      })
      .catch((error) => {
        console.log(
          "No puede iniciar sesión con las credenciales proporcionadas."
        );
        console.log(error.message);
        // guardarError(error);
      });
    // guardarMostrar(false);
  };
  const cerrarModal = () => {
    // guardarMostrar(false);
    window.location.href = "mi-perfil/";
  };
  const obtenerMembresia = async () => {
    await clienteAxios("/api/cliente/get_cliente/", {
      headers: {
        Authorization: "Bearer " + Decrypt("token"),
      },
    })
      .then((respuesta) => {
        sessionStorage.removeItem(Decrypt("informacioncliente"));
        Encrypt("informacioncliente", respuesta.data);
      })
      .catch((error) => {
        console.log(
          "No puede iniciar sesión con las credenciales proporcionadas."
        );
      });
  };
  return (
    <>
      {/* {!noreactivar ? ( */}
      <>
        <div className="cancelar-membresia-contenedor">
          <div className="pb5 pd5">
            {/* <label className="fan-etiqueta-titulo">
                ¿¿Deseas cancelar tu membresía?
              </label> */}
            {MembresiaVigente.map((membresia) => (
              <div className="row modal-ptb-4vh">
                {/* <label className="fan-etiqueta-descripcion">
                    Al reactivar tu membresía tendrás acceso nuevamente a los
                    beneficios. Puedes renovar tu membresía anterior, o subir de
                    membresía paraacceder a más beneficios.
                  </label> */}
                <div className="fan-alineacion-derecha">
                  <Button
                    variant="outlined"
                    onClick={cerrarModal}
                    className="fan-btn-noreactivar"
                  >
                    Regresar
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => onClick(membresia.id)}
                    className="fan-btn-reactivar"
                  >
                    Cancelar
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
      {/* ) : // <ModalNoreactivar />
      null} */}
    </>
  );
};

export default CancelarMembresia;
