import React, { useState } from "react";
import clienteAxios from "../config/axios";
import "./registro.css";
import { axios } from 'axios';

function Registro() {
  //   const [nombre, guardarNombre] = useState(null);
  //   const [correo, guardarCorreo] = useState(null);
  //   const [contrasena, guardarContrasena] = useState(null);
  const [login, guardarLogin] = useState({
    correo: "",
    password: "",
  });
  const [usuarios, guardarUsuarios] = useState({
    nombre: "",
    correo: "",
    password: "",
  });

  const { correo, password } = login;
  const { nombre } = usuarios;

  const obtenerInformacion = (e) => {
    guardarLogin({
      ...login,
      [e.target.name]: e.target.value,
    });
    guardarUsuarios({
      ...usuarios,
      [e.target.name]: e.target.value,
    });
  };

  const registrar = async (e) => {
    e.preventDefault();

    const respuesta = await clienteAxios.get("/login");
    guardarUsuarios(respuesta.data);
    if (
      correo == respuesta.data[0].correo &&
      password == respuesta.data[0].password
    ) {
      console.log("Usuario ya existe");
    } else {
      try {
        clienteAxios.post("/login", login);
        console.log("Exito");
      } catch (error) {
        console.log(error);
      }
      try {
        clienteAxios.post("/usuarios", usuarios);
        console.log("Exito");
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div>
      <form className="form-login" onSubmit={registrar}>
        <div className="form-group">
          <label htmlFor="" className="label-title">
            Nombre completo
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Nombre completo"
            name="nombre"
            value={nombre}
            onChange={obtenerInformacion}
          />
          <label htmlFor="" className="label-title">
            Correo
          </label>
          <input
            type="text"
            className="form-control textInputLogin"
            placeholder="Correo"
            name="correo"
            value={correo}
            onChange={obtenerInformacion}
          />
          <label htmlFor="" className="label-title">
            Contraseña
          </label>
          <input
            type="password"
            className="form-control textInputLogin"
            placeholder="Contraseña"
            name="password"
            value={password}
            onChange={obtenerInformacion}
          />
          <div className="div-link">
          <span>Creando una cuenta estas de acuerdo con nuestros </span>
            <a className="link-recuperar" href="http://35.239.108.106/micaffenio/terminos-y-condiciones/">
               Términos y
              condiciones,
            </a>
            <a className="link-recuperar" href="http://35.239.108.106/privacy-policy/">
              Politíca de servicio y Privacidad.
            </a>
          </div>
          <input
            type="submit"
            className="btn-iniciasesion"
            value="¡Regístrate!"
          />
        </div>
      </form>
    </div>
  );
}

export default Registro;
