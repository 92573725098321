import React, { useState } from "react";
import { Button } from "@material-ui/core";
import Error from "../Error";
const ModalCambiarDireccion = ({
  Direcciones,
  guardarMostrar,
  guardarDireccionseleccionada,
}) => {
  const [error, guardarError] = useState("");
  const onClick = (key) => {
    guardarMostrar(false);
    guardarDireccionseleccionada(key);
  };
  const cerrarModal = () => {
    guardarMostrar(false);
  };
  return (
    <>
      {error ? <Error mensaje={error} /> : null}
      <div className="modal-contenedor-confirmacion">
        <div className="pb5 pd5">
          <label className="miperfil-etiqueta-titulo">Dirección</label>
          {Direcciones.map((direccionPrincipal) => (
            <div className="row modal-ptb-4vh">
              <div className="modal-etiqueta-descripcion col-md-10">
                {direccionPrincipal.calle} #{direccionPrincipal.numero} entre{" "}
                {direccionPrincipal.calle_opcional} <br />
                Colonia {direccionPrincipal.colonia}{" "}
                {direccionPrincipal.municipio}, {direccionPrincipal.estado}
                <br />
                {" CP. "} {direccionPrincipal.codigo_postal}
              </div>
              <div className="modal-etiqueta-descripcion col-md-2">
                <Button
                  variant="outlined"
                  onClick={() => onClick(direccionPrincipal)}
                >
                  Seleccionar
                </Button>
                <Button variant="outlined" onClick={cerrarModal}>
                  Cerrar
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ModalCambiarDireccion;
