import React, { useState, useEffect } from "react";
import { useMapEvents } from "react-leaflet";
const Drive = ({
  drive,
  guardarBdrives,
  guardarCoordenadas,
  colocarPosicion,
}) => {
  const [estatus, guardarEstatus] = useState(null);

  useEffect(() => {
    const obtenerDiaActual = () => {
      const diaSemana = new Date();
      const diaActual = diaSemana.getDay();
      const horaActual = diaSemana.getHours();

      if (diaActual === 0) {
        guardarEstatus("cerrado");
        if (
          horaActual >=
            drive.domingo.substr(0, drive.domingo.indexOf(":", 0)) &&
          horaActual < drive.domingo.substr(drive.domingo.length - 5, 2)
        ) {
          guardarEstatus("abierto");
        }
      } else if (diaActual === 7) {
        guardarEstatus("cerrado");
        if (
          horaActual >= drive.sabado.substr(0, drive.sabado.indexOf(":", 0)) &&
          horaActual < drive.sabado.substr(drive.sabado.length - 5, 2)
        ) {
          guardarEstatus("abierto");
        }
      } else {
        guardarEstatus("cerrado");
        if (
          horaActual >=
            drive.lunes_a_viernes.substr(
              0,
              drive.lunes_a_viernes.indexOf(":", 0)
            ) &&
          horaActual <
            drive.lunes_a_viernes.substr(drive.lunes_a_viernes.length - 5, 2)
        ) {
          guardarEstatus("abierto");
        }
      }
    };
    obtenerDiaActual();
  }, []);

  return (
    <div className="info-drives">
      <p className="titulo">
        {/* {drive[0].tipo === "1" ? (
          <img
            className="icondrivecaja"
            src="../wp-content/uploads/2021/05/drive_rojo.png"
            alt=""
          />
        ) : drive[0].tipo === "2" ? (
          <img
            className="icondrivecaja"
            src="../wp-content/uploads/2021/05/drive_amarillo.png"
            alt=""
          />
        ) : (
          <img
            className="icondrivecaja"
            src="../wp-content/uploads/2021/05/drive_gris.png"
            alt=""
          />
        )}{" "} */}
        CAFFENIO {drive.nombre_drive}
      </p>
      {estatus === "abierto" ? (
        <p className="estatus">
          {" "}
          Lunes a Viernes {drive.lunes_a_viernes} <br /> Sabado {drive.sabado}{" "}
          <br /> Domingo {drive.domingo}{" "}
        </p>
      ) : (
        <p className="estatus">CAFFENIO Cerrado temporalmente</p>
      )}
      {/* <p className="estatus">CAFFENIO Cerrado temporalmente</p> */}
      <p className="direccion">{drive.direccion}</p>
      <a href="#" onClick={() => colocarPosicion(drive.coordenadas)}>
        <p className="liga">Ver en Maps</p>
      </a>
    </div>
  );
};

export default Drive;
