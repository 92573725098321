import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { clienteAxios, clienteAxiosEspressa } from './../Utils/axios';
import './murales.css';
import axios from 'axios'

const Murales = () => {

  const [hover, guardarHover] = useState(null);
  const [indexMap, guardarIndexMap] = useState(null);
  const [dropdown, guardarDropdown] = useState(false);
  const [etiquetas, guardarEtiquetas] = useState([]);
  const [ip, setIP] = useState('');

  const [etiqueta, guardarEtiqueta] = useState({
    id: 0,
    slug: 'todos',
    nombre: 'Ver todos',
  });
  const [actualizar, guardarActualizar] = useState(false);
  const [murales, guardarMurales] = useState([]);

  useEffect(() => {

    const getData = async () => {
      const res = await axios.get('https://geolocation-db.com/json/')
      setIP(res.data.IPv4)
    }

    getData();

    const obtener_etiquetas = async () => {
      try {
        const url = 'obtener_etiquetas';
        const { data } = await clienteAxiosEspressa(url);

        data.unshift({
          id: 0,
          slug: 'todos',
          nombre: 'Ver todos',
        });

        guardarEtiquetas(data);
      } catch (error) {
        console.log(error);
      }
    };
    obtener_etiquetas();

  }, []);


  useEffect(() => {
    guardarActualizar(false);

    const obtener_murales = async () => {
      let slug = 'todos';
      if (etiqueta.slug != '') {
        slug = etiqueta.slug;
      }

      const url = 'obtener_murales';
      const body = {
        ip,
        slug
      }

      try {
        const { data } = await clienteAxiosEspressa.post(url, body);
        console.log('Estos es la data de obtener murales ',data);
        guardarMurales(data);
      } catch (error) {
        console.log(error);
      }
    };

    obtener_murales();

  }, [ip, etiqueta, actualizar]);


  const onChangeEtiqueta = (op) => {
    guardarEtiqueta(op);
    guardarDropdown(false)
  };


  const agregarLike = async (id) => {
    const url = "insertar_like";
    const body = {
      id,
      ip
    }
    try {
      const { data } = await clienteAxiosEspressa.post(url, body);
      guardarActualizar(true);
    } catch (error) {
      console.log(error);
    }
  };

  const hoverMouseEnter = (index) => {
    guardarIndexMap(index);
  }

  const hoverMouseLeave = () => {
    guardarIndexMap(null);
    guardarHover(false);
  }

  const openDorpDown = () => {
    guardarDropdown(true);

    if (dropdown == true) {
      guardarDropdown(false);
    }
  }

  console.log('Esto vale murales ',murales);

  return (
    <>

      {/* <Select
        className="select-filter"
        placeholder="Seleccionar una opción"
        value={etiqueta}
        options={etiquetas}
        onChange={onChangeEtiqueta}
        getOptionValue={(x) => x.slug}
        getOptionLabel={(x) => x.nombre}
      /> */}
      <div className='text-right text-centar py-1'>
        <span className='filtro'>selecione ubicación</span>
      </div>
      <div className='background-img'>
        <div className='select-mural' onClick={openDorpDown}>
          <span>{etiqueta.nombre}</span>
          <i aria-hidden="true" class={dropdown ? "fas fa-chevron-up" : "fas fa-chevron-down"}></i>
        </div>
      </div>
      {dropdown &&
        <div className='option-mural'>
          <ul>

            {etiquetas.length > 0 &&
              etiquetas.map((eti, index) => (

                <>
                  <li onClick={() => onChangeEtiqueta(eti, index)}>
                    {eti.id != etiqueta.id ? eti.nombre : ""}
                  </li>

                </>
              ))}

          </ul>
        </div>
      }


      <div className="murales-contenedor-listado" id='vota'>
        {murales.length > 0 &&
          murales.map((mural, index) => (

            <div key={mural.id} className="murales-div-imagen">

              <div className="murales-like" onMouseEnter={() => hoverMouseEnter(index)} onMouseLeave={() => hoverMouseLeave()}>

                <button href="#" class={mural.pintar == true ? "question-link active rm-btn" : "question-link rm-btn"} onClick={() => agregarLike(mural.id)} > </button>
                <span className="murales-likes-numero">{mural.likes != null ? mural.likes : 0}</span>{' '}


              </div>


              <a className={indexMap == index ? "imagen-ref active" : "imagen-ref"} href={mural.imagen}
                data-elementor-open-lightbox="yes" data-elementor-lightbox-title={mural.title} >
              </a>

              <div className={"imagenes-murales"} >

                <img src={mural.imagen} alt="" className="murales-imagen" />

              </div>

              <div className={indexMap == index ? "murales-div-texto active" : "murales-div-texto"}
                onMouseEnter={() => hoverMouseEnter(index)} onMouseLeave={() => hoverMouseLeave()}>
                <span className="murales-span-titulo">{mural.title}</span>


              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default Murales;

